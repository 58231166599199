@import url(./darkerGrotesque/stylesheet.css);

/* Gelion Black */
@font-face {
  font-family: "Gelion";
  src: url("Gelion Black.ttf") format("truetype");
  font-weight: 900;
}

/* Gelion Bold */
@font-face {
  font-family: "Gelion";
  src: url("Gelion Bold.ttf") format("truetype");
  font-weight: 800;
}

/* Gelion Light */
@font-face {
  font-family: "Gelion";
  src: url("Gelion Light.ttf") format("truetype");
  font-weight: 200;
}

/* Gelion Medium */
@font-face {
  font-family: "Gelion";
  src: url("Gelion Medium.ttf") format("truetype");
  font-weight: 400;
}

/* Gelion Regular */
@font-face {
  font-family: "Gelion";
  src: url("Gelion Regular.ttf") format("truetype");
  font-weight: 300;
}

/* Gelion SemiBold */
@font-face {
  font-family: "Gelion";
  src: url("Gelion SemiBold.ttf") format("truetype");
  font-weight: 600; /* semi-bold */
}

/* Gelion Thin */
@font-face {
  font-family: "Gelion";
  src: url("Gelion Thin.ttf") format("truetype");
  font-weight: 100; /* thin */
}
