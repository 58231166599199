.searchContainer {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 8px 14px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    width: 18px;
    min-width: 18px;
  }

  input {
    width: 8rem;
    height: 100%;
    border: none;
    background: transparent;
    color: #667085;
    font-weight: 300;
    font-size: 13px;

    &::placeholder {
      color: #667085;
      font-weight: 300;
      font-size: 13px;
      width: fit-content;
    }
  }
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Internet Explorer 10+ */
input[type="number"] {
  -ms-appearance: textfield;
}
.transparent {
  background: transparent;
}
