.chartGraph {
  padding: 0px 1rem 30px 0rem;
  height: 340px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .chartGraph {
    padding: 0px 0rem 30px 0rem;
    height: 340px;
    width: 80%;
  }
}