.date {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  gap: 8px;
  padding: 10px 14px;
  border: 1px solid #D0D5DD;
  cursor: pointer;
  height: fit-content;

  .text {
    color: #101323;
    font-weight: 400;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
  }

  input[type="date"]::-ms-clear {
    display: none;
  }

  input[type="date"] {
    position: relative;
    z-index: 1;
  }

  .dateInput {
    // display: flex;
    align-items: center;
    .mainInput {
      border: none;
      cursor: pointer;
      padding: 0;
      height: 0px;
      width: 0px;
    }
    .input {
      margin: 0;
    }
    .startDate {
      display: block;
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
  .dates {
    display: flex;
    gap: 10px;
    color: #101323;
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .date {
    .dates {
      font-size: 12px;
    }
  }
}