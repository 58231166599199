.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0.7rem;

  p {
    b {
      font-weight: 500;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .numberNav {
      display: flex;
      gap: 2rem;
      font-weight: 500;
      span:hover {
        color: #605BFF;
        cursor: pointer;
      }
      .activePage {
        color: #605BFF;
      }
    }
    button {
      cursor: pointer;
      padding: 8px 12px;
      border: none;
      display: flex;
      gap: 5px;
      svg {
        path {
          fill: #5F6D7E;
        }
      }
      .arrowRight {
        transform: rotate(180deg);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
}