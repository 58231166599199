.layout {
  position: relative;

  .navBar {
    width: 100%;
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-between;
  }

  .navNone {
    display: none;
  }

  .sideBar::-webkit-scrollbar {
    width: 0;
  }

  .sideBar {
    padding-top: 16px;
    background: #1D2939;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
    z-index: 2;
    overflow-y: auto;
    scrollbar-width: none;

    .sidebarList {
      color: var(--white);
      margin-bottom: auto;
      list-style: none;
      height: 100%;
      display: flex;
      flex-direction: column;

      .user {
        border-radius: 5px;
        padding: 8px 16px 8px 15px;
        margin: 0 1.6rem 15px 1.6rem;
        border: 1px solid #667085;
        background: #344054;
        display: flex;
        gap: 0.7rem;

        .pfpImage {
          width: 38px;
          height: auto;
        }

        .details {
          padding-right: 2.0rem;

          .head {
            color: #FFFFFF;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
            font-size: 15px;
            white-space: nowrap;
            max-width: 100px;
            overflow-x: auto;
            text-overflow: ellipsis;
          }

          color: #A5ACBA;

          .email {
            font-size: 13px;
            white-space: nowrap;
            max-width: 100px;
            overflow-x: auto;
            text-overflow: ellipsis;
          }
        }
      }

      .items {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .content {
    padding: 2rem 1.8rem;
    width: calc(100% - 260px);
    margin-left: 260px;
    position: absolute;
  }

  .content::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: 1100px) {
  .layout {
    z-index: -1;
    .sideBar {
      top: 68px;
      height: calc(100% - 68px);
      left: -300px;
      transition: left 0.3s ease;
      z-index: 999;
    }

    .show {
      left: 0;
    }
    .navBar {
      z-index: -999;
    }

    .navNone {
      display: flex;
      position: fixed;
      z-index: 10;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      background: #FFFFFF;

      .logo {
        width: 160px;
        height: auto;
      }

      .menuIcon {
        width: 35px;
        height: auto;
        transition: transform 0.5s ease;
      }

      .rotate {
        width: 35px;
        height: auto;
        transition: transform 0.5s ease;
        transform: rotate(90deg);
      }

    }

    .content {
      width: 100%;
      margin-left: 0;
      margin-top: 68px;
    }

    .logo svg {
      width: 28px;
    }

    .logo p {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 800px) {
  .layout {

    .sideBar {
      top: 59px;
      height: calc(100% - 59px);
    }
    .navNone {

      .logo {
        width: 120px;
        height: auto;
      }

      .menuIcon {
        width: 25px;
        height: auto;
      }

      .rotate {
        width: 25px;
        height: auto; 
      }
    }

    .content {
      width: 100%;
      margin-top: 59px;
    }
  }
}

@media screen and (max-width: 600px) {
  .layout {
    .sideBar {
      top: 55px;
      height: calc(100% - 55px);
    }
    .navNone {

      .logo {
        width: 100px;
        height: auto;
      }

      .menuIcon {
        width: 20px;
        height: auto;
      }

      .rotate {
        width: 20px;
        height: auto;
      }
    }
    .content {
      margin-top: 55px;
    }
  }
}

@media screen and (max-width: 900px) {
  .layout {
    .content {
      padding: 1.7rem 1.2rem;
    }
  }
}