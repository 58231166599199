.userDropdown {
  font-size: 16px;
  color: #98A2B3;
  padding: 10px 1.6rem 10px 1.2rem;
  margin-left: 1.6rem;
}

.userDropdown:hover,
.activeItem {
  border-left: 3px solid #F9F9F9;
  color: #fff;
  background: #344054;
  cursor: pointer;
}