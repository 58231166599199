@mixin flex-center($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-style($color, $font-weight, $cursor: auto) {
  color: $color;
  font-weight: $font-weight;
  cursor: $cursor;
}
.filterTabs {
  margin-top: 20px;
}
.reports {
  .cards {
    .cardScroll {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.2rem;
    }
  }

  .candidateInfo {
    margin: 30px 0px;

    .candidateHead {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .candidateTitle {
        font-weight: 500;
        font-size: 16px;
        color: #101323;
      }

      .right {
        display: flex;
        align-items: center;
        gap: 15px;

        .leftArrow {
          background: #F9FAFB;
          padding: 8px 8px 4px 8px;
          border-radius: 3px;
          cursor: pointer;
        }

        .rightArrow {
          background: #F9FAFB;
          padding: 8px 8px 4px 8px;
          transform: rotate(180deg);
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }
    .candidateFilterTabs {
      margin-bottom: 20px;
    }
    .candidateCards {
      display: flex;
      overflow-x: scroll;
      scroll-behavior: smooth;
      gap: 1rem;

      .card {
        flex: 0 0 auto;
        width: calc((100% / 3) - (2 / 3 * 1rem));
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #EAECF0;
        border-radius: 8px;
        padding: 16px 20px;

        .left {
          display: flex;
          align-items: center;
          gap: 1rem;

          .icon {
            padding: 8px 8px 4px 8px;
            border-radius: 5px;
            background: #605BFF;
            width: fit-content;
            height: fit-content;
          }

          .texts {
            display: flex;
            flex-direction: column;

            .textTitle {
              font-size: 12px;
              color: #667085;
            }

            .textNo {
              color: #101323;
              font-size: 36px;
              line-height: 35px;
              font-weight: 800;
              font-family: 'Darker Grotesque';
            }
          }
        }

        .rate {
          display: flex;
          align-items: center;
          gap: 8px;

          .growth {
            background: rgba(230, 255, 243, 0.7);
            display: flex;
            gap: 4px;
            border-radius: 5px;
            padding: 2px 8px;

            .growthDesc {
              font-size: 13px;
              color: #18B368;
            }

            .growthArrow {
              path {
                stroke: #18B368;
              }
            }
          }

          .even {
            background: #F5FAFF;
            display: flex;
            gap: 4px;
            border-radius: 5px;
            padding: 2px 8px;

            .evenDesc {
              font-size: 13px;
              color: #0081CC;
            }

            .evenArrow {
              transform: rotate(90deg);
              path {
                stroke: #0081CC;
              }
            }
          }

          .loss {
            background: #FFE6E6;
            display: flex;
            gap: 4px;
            border-radius: 5px;
            padding: 2px 8px;

            .lossDesc {
              font-size: 13px;
              color: #E62E2E;
            }

            .lossArrow {
              transform: rotate(180deg);

              path {
                stroke: #E62E2E;
              }
            }
          }

        }

      }
    }
  }

  .chart {
    margin-top: 30px;
    border: 1px solid #EAECF0;
    border-radius: 10px;
    width: 100%;

    .chartHead {
      padding: 16px 20px;
      border-bottom: 1px solid #EAEBF0;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 16px;
      color: #101323;
    }

    .chartGraph {
      padding: 0px 1rem 30px 2rem;
      height: 340px;
      width: 100%;
    }

    .chartLine {
      border-top: 1px solid #D0D5DD;
      display: flex;
      gap: 20px;
      width: 95%;
      margin: 0 auto;

      .active {
        color: #605BFF;
      }

      span {
        font-size: 16px;
        font-weight: 400;
        padding: 15px 0px;
        color: #101323;
        cursor: pointer;
        margin-top: -1px;
      }

      span:hover {
        border-top: 1px solid #605BFF;
      }
    }
  }

  .filterTab {
    border-radius: 8px;
    padding: 4px 8px;
    background: #F9FAFB;
    display: flex;
    width: fit-content;
    gap: 0.5rem;

    .filterBtn {
      padding: 10px 35px;
      color: #101323;
      font-weight: 300;
      font-size: 16px;
      cursor: pointer;
    }

    .filterBtn:hover {
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      background: #FFFFFF;
      border-radius: 8px;
      font-weight: 400;
    }

    .active {
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      background: #FFFFFF;
      border-radius: 8px;
      font-weight: 400;
    }
  }

  .table {
    margin-top: 30px;
    border: 1px solid #EAECF0;
    border-radius: 10px;

    .tableBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.2rem 1rem 1.2rem;

      .head {
        color: #101323;
        font-size: 16px;
        font-weight: 400;
      }

      .tableActions {
        display: flex;
        gap: 1rem;

        .filterScroll {
          max-width: 500px;

          .filter {
            display: flex;
            align-items: center;
            overflow-x: scroll;
            scroll-behavior: smooth;
            gap: 1rem;

            .filterRange {
              p {
                white-space: nowrap;
              }
            }
          }
        }

      }

    }

    .emptyTable {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .tableWrap {
      min-width: 500px;
    }

    .tableItem {
      display: flex;
      align-items: center;
    }

    .reportTableHeader {
      grid-template-columns: 1.8fr 1.3fr 0.7fr 0.8fr 0.7fr 0.7fr 0.3fr;
      gap: 0px;
    }

    .reportTableBodyItem {
      font-size: 13px;
      font-weight: 300;
      color: #667085;
      padding: 20px 1.2rem 20px 1.2rem;
      grid-template-columns: 1.8fr 1.3fr 0.7fr 0.8fr 0.7fr 0.7fr 0.3fr;
      gap: 0px;
    }

    .headerStyle:last-of-type {
      visibility: visible;
      display: block;
    }

    .paginationBtn {
      background: none;
      color: #667085;
    }
  }
}

@media screen and (max-width: 970px) {
  .reports {
    .cards {
      overflow-x: auto;
      scrollbar-width: none;

      .cardScroll {
        min-width: 600px;
      }
    }

    .candidateInfo {
      .candidateCards {
        .card {
          width: calc((100% / 2) - (1 / 2 * 1rem));
        }
      }
    }
  }
}



@media screen and (max-width: 900px) {
  .reports {
    .table {
      .tableBar {
        .tableActions {
          .filterScroll {
            max-width: 250px;

            .filter {
              display: flex;
              align-items: center;
              overflow-x: scroll;
              scroll-behavior: smooth;
              gap: 1rem;

              .filterRange {
                p {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .reports {
    .cards {
      overflow-x: auto;
      scrollbar-width: none;

      .cardScroll {
        min-width: 600px;
      }
    }

    .candidateInfo {
      .candidateCards {
        .card {
          width: calc((100%) - (0.8rem));
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .reports {
    .cards {
      overflow-x: auto;
      scrollbar-width: none;

      .cardScroll {
        min-width: 600px;
      }
    }

    .table {
      .tableBar {
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;

        .tableActions {
          align-items: center;

          .filterScroll {
            max-width: 160px;
          }

          .search {
            padding: 10px 10px;
            height: fit-content;

            svg {
              width: 12px;
              min-width: 12px;
            }

            input {
              font-size: 12px;
              width: 5rem;
              height: 10px;
            }

            input::placeholder {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .reports {
    .table {
      .tableBar {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .tableActions {
          .filterScroll {
            max-width: 240px;
          }

          .search {
            padding: 11px 10px;
            height: fit-content;

            svg {
              width: 12px;
              min-width: 12px;
            }

            input {
              font-size: 12px;
              width: 5rem;
              height: 10px;
            }

            input::placeholder {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .reports {
    .table {
      .tableBar {
        .tableActions {
          .filterScroll {
            max-width: 140px;
          }
        }
      }
    }
  }
}