.date {
  align-items: center;
  margin-right: 10px;
}

.statusSection {
  .status {
    display: flex;
    gap: 4px;
    padding: 2px 5px;
    border-radius: 5px;

    .dotClosed {
      rect {
        fill: #E62E2E;
      }
    }
    .dotScheduled {
      rect {
        fill: #DB8400;
      }
    }
  }
  .textClosed {
    background: #FFF2F0;
    color: #E62E2E;
  }
  .textScheduled {
    background: #FFF5E6;
    color: #EEA23E;
  }
  .textActive {
    background: rgba(230, 255, 243, 0.7);
    color: rgba(57, 205, 98, 1);
  }
  .textPaused {
    background: rgba(230, 255, 243, 0.7);
    color: rgba(57, 205, 98, 1);
  }
}

.dots {
  position: absolute;
  right: 1rem;
}



.selectIcon {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .title {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 600px) {
  .title {
    max-width: 90px;
  }
}