.stackSection {
  padding: 20px 1rem;
  background: #FAFAFB;
  border-radius: 10px;
  margin-bottom: 30px;

  .stack {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 2rem;

      .chart {
        width: 120px;
      }

      .deets {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .stackHead {
          color: #101323;
          font-size: 18px;
          font-weight: 500;
        }

        .stackText {
          font-size: 18px;
        }
      }
    }

    .right {
      color: #101323;
      cursor: pointer;
      transition: transform 0.5s ease;
    }
  }

  .line {
    border-bottom: 2px solid #F2F4F7;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .question {
    .questionText {
      color: #101323;

      .star {
        color: #E62E2E;
      }
    }

    .questionProp {
      color: #667085;
      margin-bottom: 10px;
    }

    .options {
      .option {
        border-radius: 8px;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;

        .opt {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }

      .border {
        border: 1px solid #E74444;
        background: #E5F4E8;
      }
    }
  }
}