.users {
  padding: 20px 15px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  cursor: pointer;

  .usersHead {
    display: flex;
    justify-content: space-between;
    .head {
    font-size: 16px;
    font-weight: 300;
    color: #101323;
    }
    .select {
      cursor: pointer;
    }
  }

  .number {
    font-size: 38px;
    font-weight: 800;
    color: #101323;
    font-family: 'Darker Grotesque';
    margin: 10px 0px 10px 0px;
  }
  .rate {
    display: flex;
    align-items: center;
    gap: 8px;
    .growth {
      background: rgba(230, 255, 243, 0.7);
      display: flex;
      gap: 4px;
      border-radius: 5px;
      padding: 2px 8px;
      
      .growthDesc {
        font-size: 13px;
        color: #18B368;
      }
      .growthArrow {
        path {
          stroke: #18B368;
        }
      }
    }
    .even {
      background: #F5FAFF;
      display: flex;
      gap: 4px;
      border-radius: 5px;
      padding: 2px 8px;
      
      .evenDesc {
        font-size: 13px;
        color: #0081CC;
      }
      .evenArrow {
        transform: rotate(90deg);
        path {
          stroke: #0081CC;
        }
      }
    }
    .loss {
      background: #FFE6E6;
      display: flex;
      gap: 4px;
      border-radius: 5px;
      padding: 2px 8px;
      
      .lossDesc {
        font-size: 13px;
        color: #E62E2E;
      }
      .lossArrow {
        transform: rotate(180deg);
        path {
          stroke: #E62E2E;
        }
      }
    }

  }
}

@media screen and (max-width: 900px) {
  .users {
    padding: 15px 10px;
    .usersHead {
      .head {
       font-size: 12px 
      }
      .select {
        width: 11px;
      }
    }
    .number {
      font-size: 25px;
    }
    .rate {
      font-size: 11px;
      .growth {
        .growthDesc {
          font-size: 11px;
        }
      }
    }
    .new {
     .newDesc {
      font-size: 11px;
     } 
    }
    .loss {
     .lossDesc {
      font-size: 11px;
     } 
    }
  }
}

@media screen and (max-width: 650px) {
  .users {

    .number {
      margin: 5px;
    }

  }
}