.nameSection {
  display: flex;
  align-items: center;
  gap: 1rem;

  .check {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .nameFlex {
    display: flex;
    flex-direction: column;

    .businessName {
      color: #101323;
      font-weight: 500;
      white-space: nowrap;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .businessEmail {
      text-transform: lowercase;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .businessEmail::-webkit-scrollbar {
      width: 0px;
    }
  }
}

.namee {
  .scrollable {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.statusWrapper {
  .scrollable {
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.no {
  margin-left: 30%;
}


.statusSection {
  .status {
    display: flex;
    gap: 4px;
    padding: 2px 5px;
    border-radius: 5px;

    .textInactive {
      background: #FFF2F0;
      color: #E2341D;
    }

    .textActive {
      background: rgba(230, 255, 243, 0.7);
      color: rgba(57, 205, 98, 1);
    }
  }

  .textTerminated {
    background: #FFF2F0;
    color: #E2341D;

    .dotTerminated {
      rect {
        fill: #E2341D;
      }
    }
  }

  .textNaN {
    background: #FFF2F0;
    color: #E2341D;

    .dotNaN {
      rect {
        fill: #E2341D;
      }
    }
  }

  .textActive {
    background: rgba(230, 255, 243, 0.7);
    color: rgba(57, 205, 98, 1);
  }

  .textDormant {
    background: #FFF5E6;
    color: #EEA23E;

    .dotDormant {
      rect {
        fill: #DB8400;
      }
    }
  }

  .textPending {
    background: #FFF5E6;
    color: #EEA23E;

    .dotPending {
      rect {
        fill: #DB8400;
      }
    }
  }
}

.dots {
  position: absolute;
  right: 1rem;
}



.selectIcon {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .planType {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 1100px) {
  .nameSection {
    .scrollable {
      align-items: center;

      .nameFlex {
        .businessName {
          max-width: 100px;
        }
      }
    }
  }

  .businessEmail {
    max-width: 130px;
  }
}

@media screen and (max-width: 650px) {
  .nameSection {
    gap: 0.5rem;

    .check {
      width: 16px;
      height: 16px;
      border-radius: 3px;
    }
  }
}