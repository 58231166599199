  .header {
    font-size: 24px;
    margin-top: 30px;
    color: #101323;
    font-weight: 500;
  }

.singleReview {
  padding: 50px 0px 0px 0px;
  color: #000000;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .head {
    font-size: 20px;
    font-weight: 500;
    width: 80%;
    text-align: center;
  }
  .subHead {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .rates {
    .rateNumbers {
      display: flex;
      gap: 1rem;
      .circle {
        border: 1px solid #EFEFFF;
        border-radius: 50%;
        background: #FCFCFF;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        margin-bottom: 20px;
        position: relative;
          .number {
            position: absolute;
            font-size: 24px;
            font-weight: 400;
            line-height: 1;
          }
        
      }
      .pick {
        border: 4px solid #EFEFFF;
        background: #EDA12F;
        .number {
          color: #FFFF;
        }
      }
    }
    .rateText {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .level {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  .commentHead {
    font-size: 20px;
    font-weight: 400;
    width: 68%;
    text-align: center;
  }
  .comment {
    color: #667085;
    font-size: 18px;
    border: 1px solid rgba(96, 91, 255, 0.5);
    border-radius: 10px;
    padding: 30px 2rem;
    width: 75%;
  }
}

@media screen and (max-width: 950px) {
  .singleReview {
    .head {
      font-size: 18px;
      width: 90%;
    }
    .subHead {
      font-size: 16px;
      font-weight: 300;
    }
    .rates {
      .rateNumbers {
        .circle {
          width: 50px;
          height: 50px;
          .number {
            font-size: 16px;
          }
        }
      }
    }
    .commentHead {
      font-size: 16px;
      font-weight: 300;
      width: 95%;

    }
    .comment {
      padding: 15px 1.5rem;
      margin-bottom: 40px;
      width: 95%;
    }
  }
}


@media screen and (max-width: 650px) {
  .header {
    font-size: 22px;
  }
  .singleReview {
    padding-top: 30px;
    gap: 25px;
    .head {
      font-size: 14px;
      width: 100%;
    }
    .subHead {
      font-size: 14px;
      font-weight: 300;
    }
    .rates {
      // width: 100%;
      .rateNumbers {
        .circle {
          width: 30px;
          height: 30px;
          .number {
            font-size: 14px;
            line-height: 0;
          }
        }
        .pick {
          border: 2px solid #EFEFFF;
        }
      }
    }
    .commentHead {
      font-size: 14px;
      font-weight: 300;
      width: 100%;
    }
    .comment {
      padding: 15px 1rem;
      margin-bottom: 30px;
      font-size: 14px;
      width: 95%;
      
    }
  }
}


@media screen and (max-width: 480px) {
  .singleReview {
    gap: 20px;
    .head {
      font-size: 16px;
    }
    .subHead {
      font-size: 14px;
    }
    .rates {
      width: 100%;
      .rateNumbers {
        gap: 0px;
        max-width: 400px;
        overflow-x: scroll;

        .circle {
          width: auto;
          height: auto;
          margin-right: 10px;
          padding: 15px;
          .number {
            font-size: 12px;
          }
        }
      }
      .rateText {
        .level {
        font-size: 12px;}
      }
    }
    .commentHead {
      font-size: 12px;
    }
    .comment {
      padding: 15px 1rem;
      margin-bottom: 30px;
      font-size: 14px;
    }
  }
}