@mixin flex-center($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-style($color, $font-weight, $cursor: auto) {
  color: $color;
  font-weight: $font-weight;
  cursor: $cursor;
}

@mixin button($gap, $font-size, $font-weight, $padding) {
  gap: $gap;
  font-size: $font-size;
  font-weight: $font-weight;
  padding: $padding;
}

.modal {
  background: none !important;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  margin-right: 0rem;
  padding: 0;
  max-width: 567px;

  .close {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 8px 10px 6px 10px;
    margin-top: 20px;
  }

  .container {
    background: #FFFFFF;
    border-radius: 16px;
    width: 560px;
    padding: 2rem 1.5rem;
    position: relative;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .name {
        font-weight: 700;
        color: #101323;
        font-family: 'Darker Grotesque';
      }
    }

    .search {
      width: 40%;
      margin-bottom: 30px;

      input {
        font-size: 14px;
        width: 5rem;
      }

      input::placeholder {
        font-size: 14px;
      }
    }

    .table {
      border: 1px solid #EAECF0;
      border-radius: 10px;
      margin-bottom: 70px;

      .row {
        display: flex;
        padding: 12px 24px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EAECF0;

        .nameSection {
          display: flex;
          align-items: center;
          gap: 1rem;

          .check {
            width: 18px;
            height: 18px;
            border-radius: 4px;
          }

          .nameFlex {
            display: flex;
            flex-direction: column;

            .businessName {
              color: #101323;
              font-weight: 500;
            }
          }

        }

        .download {
          color: #605BFF;
          font-weight: 400;
        }
      }

      .row:last-of-type {
        border-bottom: none;
      }
    }

    .employerSelected {
      width: calc(100% - 3rem);
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;
      background: #101323;
      border-radius: 8px;
      position: absolute;
      bottom: 10px;
      left: 10;
      // right: 1.5rem;

      @include flex-center(space-between, center);

      .amount {
        color: #FFFFFF;
        font-weight: 300;
      }

      .buttons {
        @include flex-center(center, center);
        gap: 10px;

        .requestBtn {
          background: #FFFFFF;
          border: none;
          color: #1D2939;
          @include button(6px, 14px, 300, 4px 8px);
        }

        .deleteBtn {
          @include button(6px, 14px, 300, 4px 8px);
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .modal {
    max-width: 100%;
    width: 100%;

    .container {
      width: 100%;
      overflow-x: scroll;

      .date {
        .start {
          font-size: 13px !important;
        }
      }

      .questions {
        font-size: 13px;
      }

      .scroll {

        overflow-x: scroll;

        .btns {
          min-width: 400px;
        }
      }
    }
  }
}