.title {
  max-width: 180px;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}

.title::-webkit-scrollbar {
  width: 0px;
}

.statusSection {
  .status {
    display: flex;
    gap: 4px;
    padding: 2px 5px;
    border-radius: 5px;

    .dotClosed {
      rect {
        fill: #E62E2E;
      }
    }
    .dotScheduled {
      rect {
        fill: #DB8400;
      }
    }
    .dotDraft {
      rect {
        fill: #DB8400;
      }
    }
  }
  .textClosed {
    background: #FFF2F0;
    color: #E62E2E;
  }
  .textScheduled {
    background: #FFF5E6;
    color: #EEA23E;
  }
  .textDraft {
    background: #FFF5E6;
    color: #EEA23E;
  }
  .textActive {
    background: rgba(230, 255, 243, 0.7);
    color: rgba(57, 205, 98, 1);
  }
}

.textDays {
  color: #18B368;
  font-weight: 400;
}

.textEnded {
  color: #E62E2E;
  font-weight: 400;
}

.textNotLive {
  color: #EDA12F;
  font-weight: 400;
}

.dots {
  position: absolute;
  right: 1rem;
}



.selectIcon {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1210px) {
  .deadline {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 650px) {
  
}