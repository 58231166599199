.progressBar {
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: 2rem;
  position: relative;
  width: 360px;
  margin: 3rem auto;

  * {
    transition: 0.3s;
  }

  &__rating {
    position: absolute;
    left: 0;
    background: var(--primary-blue);
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    margin-left: -30px;
    z-index: 10;
  }

  &__bar {
    width: 0px;
    height: 10px;
    background: var(--primary-blue);
    border-radius: 20px;
    transition: width 0.3s ease-out;
    position: absolute;
    z-index: 1;
    left: 0;

    &__white {
      background: rgba(107, 107, 117, 0.12);
      border-radius: 20px;
      width: 100%;
      height: 10px;
    }
  }
}

.ratingBar {
  position: relative;
  z-index: 2;
  display: flex;
  position: relative;
  width: 100%;

  * {
    transition: 0.3s;
  }

  &__rating {
    position: absolute;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    z-index: 10;
  }

  &__bar {
    width: 0px;
    height: 10px;
    background: var(--primary-blue);
    border-radius: 10px;
    transition: width 0.3s ease-out;
    position: absolute;
    z-index: 1;
    left: 0;

    &__white {
      background: rgba(107, 107, 117, 0.12);
      border-radius: 20px;
      width: 100%;
      height: 10px;
    }
  }
}

.full {
  background: #fafafb;
  display: flex;
  align-items: center;
  padding: 2rem;

  > p {
    font-weight: 600;
    font-size: 16px;
    margin-right: 20%;
  }
  &__bar {
    margin: 1rem 0;
  }
}

@media screen and (max-width: 600px) {
  .progressBar {
    width: 90%;
    max-width: 360px;

    &__rating {
      font-size: 14px;
    }
  }

  .ratingBar {
    &__rating {
      font-size: 14px;
    }

    &__bar {
      height: 10px;
      &__white {
        height: 10px;
      }
    }
  }

  .full {
    padding: 1rem;
    > p {
      font-size: 14px;
    }
  }
}