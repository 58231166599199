.modal {
  background: none !important;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  margin-right: 0rem;
  padding: 0;
  max-width: 567px;

  .close {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 8px 10px 6px 10px;
    margin-top: 20px;
  }

 .container{
  background: #FFFFFF;
  border-radius: 16px;
  width: 560px;
  padding: 2rem 1.5rem;
  .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .name {
      font-weight: 700;
      color: #101323;
      font-family: 'Darker Grotesque';
    }
    .cancel {
      transform: rotate(45deg);
      cursor: pointer;
      height: 18px;
      width: 18px;
    }
  }
  .date {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .start {
      display: flex;
      flex-direction: column;
      gap: 15px;
      
      .title {
        color: #101323;
        font-size: 15px;
        font-weight: 400;
      }
      .time { color: #667085; }
      .total {
        font-weight: 400;
        color: #101323;
        span { 
          color: #667085;
          font-weight: 300;
        }
      }
    }
    .end {
      text-align: end;
    }
  }
  .questions {
    color: #101323;
    font-size: 15px;
    font-weight: 400;
    margin: 40px 0px 15px 0px;
  }
  .scroll {
    .btns {
      display: flex;
      gap: 1rem;
      .btn {
        text-transform: capitalize;
        padding: 8px 16px;
        border: 1px solid #101323;
        color: #101323;
        background: rgba(229, 225, 252, 0.4);
        border-radius: 20px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .description {
    margin-top: 30px;
    width: 100%;
    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-right: 1rem;
      font-weight: 400;
      span {
        color: #101323;
      }
      .chevron {
        cursor: pointer;
        transition: transform 0.5s ease;
      }

      .up {
        transform: rotate(180deg);
      }
    }
    .body {
      transition: height 0.5s ease;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      font-size: 15px;
      li {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .last {
        margin-top: 15px;
      }
    }
  }
 }
}

@media screen and (max-width: 650px) {
  .modal {
    max-width: 100%;
    width: 100%;
    .container {
      width: 100%;
      overflow-x: scroll;
      .date {
        .start {
          font-size: 13px !important;
        }
      }
      .questions {
        font-size: 13px;
      }
      .scroll {
        
        overflow-x: scroll;
        .btns {
          min-width: 400px;
        }
      }
    }
  }
}