.main {
  .candidateReport {
  .general {
    margin-top: 20px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .details {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          font-size: 18px;
          font-weight: 400;
          color: #101323;
        }

        .text {
          font-size: 16px;
          color: #667085;
        }
      }

      .chevron {
        width: 40px;
        cursor: pointer;
        transition: transform 0.5s ease;

        path {
          stroke: #667085;
        }
      }

      .up {
        transform: rotate(180deg);
      }
    }

    .body {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 2fr 4fr 1.5fr;

      .chart {
        width: 100%;
        margin: 20px 0px;
        cursor: pointer;
      }

      .candidatesDetails {
        width: 90%;
        padding-left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        .texts {
          color: #101323;
          font-size: 18px;
        }

        .name {
          color: #101323;
          font-size: 48px;
          font-weight: 500;
          line-height: 1;
        }

        .deets {
          display: flex;

          .number {
            color: #667085;
            font-size: 18px;
            padding-left: 1rem;
          }

          .email {
            color: #667085;
            font-size: 18px;
            border-right: 2px solid #EAECF0;
            padding-right: 1rem;
          }
        }

        .btns {
          margin-top: 20px;
          display: flex;
          gap: 1rem;

          .cvBtn {
            padding: 12px 22px;
            border: 1px solid #EAECF0;
            font-weight: 500;
            color: #667085;
            gap: 10px;
            box-shadow: 4px 7px 5px -2px rgba(16, 24, 40, 0.04);
            display: flex;
            align-items: center;
            transition: 0.3s;
            border-radius: 6px;
            justify-content: center;
            letter-spacing: 0.3px;
            font-size: 15px;
            background: transparent;

            svg {
              path {
                fill: #667085;
              }
            }
          }
        }

      }

      .reportBtn {
        border: 1px solid #EAECF0;
        font-weight: 500;
        color: #667085;
        gap: 10px;
        box-shadow: 4px 7px 5px -2px rgba(16, 24, 40, 0.04);
        height: fit-content;
        display: flex;

        svg {
          path {
            fill: #667085;
          }
        }
      }
    }
  }

  .specific {
    .filter {
      .filterTab {
        margin-top: 20px;
        border-radius: 8px;
        padding: 4px 8px;
        background: #F9FAFB;
        display: flex;
        justify-content: space-between;
        width: fit-content;
        gap: 0.5rem;
        width: 100%;

        .filterBtn {
          padding: 10px 20px;
          color: #101323;
          font-weight: 300;
          font-size: 14px;
          cursor: pointer;
        }

        .filterBtn:hover {
          box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
          background: #FFFFFF;
          border-radius: 8px;
          font-weight: 400;
        }

        .active {
          box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
          background: #FFFFFF;
          border-radius: 8px;
          font-weight: 400;
        }

      }
    }

    .basic {
      .sectionHead {
        margin-top: 100px;
        font-size: 22px;
        font-weight: 500;
        color: #101323;
        display: flex;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;

        .linee {
          border-bottom: 2px solid #F2F4F7;
          height: 2px;
          width: 100%;
        }

        .chevron {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }

      .summary {
        .summaryText {
          margin: 30px 0px;
          line-height: 1.5;
        }

        .keyPoints {
          background: rgba(229, 225, 252, 0.4);
          padding: 24px 32px;
          border-left: 4px solid #605BFF;
          border-radius: 0px 8px 8px 0px;
          margin-bottom: 30px;
          line-height: 1.5;

          li {
            margin-left: 1.2rem;
          }
        }
      }

      .basicExpectations {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
        gap: 2rem;

        .chartt {
          padding-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          .dough {
            width: 80%;
          }

          .explanation {
            width: 95%;
            padding: 8px 16px;
            border-radius: 8px;
            background-color: #F9FAFB;
            color: #667085;
            font-size: 16px;
          }
        }

        .experienceLevel {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 25px;

          .title {
            font-weight: 500;
            font-size: 16px;
            color: #101323;
          }

          .text {
            color: #101323;
            font-size: 16px;
            font-weight: 400;
          }

          .grey {
            color: #667085;
            font-weight: 400;
          }
        }

        .expectation {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 40px;

          .title {
            font-weight: 500;
            font-size: 16px;
            color: #101323;
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 28px;

            .grey {
              color: #667085;
              font-weight: 400;
            }

            .conflict {
              color: #E74444;
              display: flex;
              align-items: center;
              gap: 5px;
              line-height: 1;

              svg {
                width: 15px;
                height: 15px;
                margin-bottom: 3px;
              }
            }

            .match {
              color: #149B32;
              display: flex;
              align-items: center;
              gap: 1rem;
              line-height: 1;

              svg {
                width: 15px;
                height: 15px;
                margin-bottom: 3px;
              }
            }
          }

        }
      }

      .softSkills {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5rem;

        .chartt {
          padding-top: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          .dough {
            width: 60%;
          }

          .ch {
            display: flex;
            flex-direction: column;
            gap: 60px;

            .explanation {
              width: 95%;
              padding: 8px 16px;
              border-radius: 8px;
              background-color: #F9FAFB;
              color: #667085;
              font-size: 16px;
            }

            .rating {
              width: 95%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              .level {
                width: 100%;
                padding: 8px 16px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-left: 4px solid #18B368;
                background-color: rgba(230, 255, 243, 0.7);
                color: #667085;

                .amount {
                  color: #101323;
                }
              }

              .effective {
                background-color: rgba(229, 225, 252, 0.4);
                border-left: 4px solid #605BFF;
              }

              .average {
                background-color: rgba(255, 245, 230, 1);
                border-left: 4px solid #EDA12F;
              }

              .growth {
                background-color: #FFE6E6;
                border-left: 4px solid #E62E2E;
              }
            }
          }


        }

        .half {
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 40px;

          .analytical {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .head {
              font-size: 20px;
              color: #101323;
              font-weight: 400;

            }

            .bar {
              background-color: #18B368;
            }

            .desc {
              display: flex;
              justify-content: space-between;

              .result {
                color: #18B368;
                display: flex;
                flex-direction: column;
                gap: 5px;
                font-size: 16px;
              }

              .expectations {
                color: #18B368;
                display: flex;
                flex-direction: column;
                gap: 5px;
                font-size: 16px;

                .percentage {
                  font-weight: 400;
                  color: #101323;
                }

                .text {
                  color: #667085;

                }
              }
            }

            .word {
              font-size: 16px;
            }
          }

          .agile {
            .bar {
              background-color: #E62E2E;
            }

            .desc {
              .result {
                color: #E62E2E;
              }
            }
          }

          .creative {
            .bar {
              background-color: #EDA12F;
            }

            .desc {
              .result {
                color: #EDA12F;
              }
            }
          }

          .lead {
            .bar {
              background-color: #605BFF;
            }

            .desc {
              .result {
                color: #605BFF;
              }
            }
          }
        }

        .secondHalf {}
      }

      .workStyle {
        .half {
          .analytical {
            .word {
              color: #667085;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .point {
                .pointHead {
                  font-weight: 400;
                }

                .pointBody {
                  li {
                    margin-left: 1.4rem;
                  }
                }
              }

            }
          }
        }
      }

      .workspaceQualities {
        grid-template-columns: 1fr 2fr;

        .chartt {
          .ch {
            .rating {
              .level {
                background-color: rgba(229, 225, 252, 0.4);
                border-left: 4px solid #605BFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #101323;

                .tickIcon {
                  path {
                    fill: #605BFF;
                  }
                }
              }
            }
          }
        }

        .rightSide {
          .half {
            .analytical {
              .head {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 10px 0px;
                border-bottom: 1px solid #EAECF0;

                .matchTick {
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  font-size: 14px;
                  font-weight: 300;
                  color: #3DCC87;

                  svg {
                    path {
                      fill: #3DCC87;
                    }
                  }
                }
              }

              .desc {
                margin-top: 15px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 3rem;

                .point {
                  width: 50;

                  .pointHead {
                    color: #101323;
                    font-weight: 600;
                  }

                  .pointBody {
                    li {
                      margin-left: 1.5rem;
                      line-height: 1.5;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .workMotivators {
        .chartt {
          .ch {
            .rating {
              .red {
                border-left: 4px solid #E62E2E;

                .tickIcon {
                  path {
                    fill: #E62E2E;
                  }
                }
              }
            }
          }
        }

        .desc {
          margin-top: 80px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 3rem;

          .analytical {
            .head {
              padding: 10px 0px;
              color: #667085;
              display: flex;
              justify-content: space-between;

              .matchTick {
                color: #E62E2E;
                display: flex;
                gap: 0.5rem;
                align-items: center;

                svg {
                  path {
                    fill: #E62E2E;
                  }
                }
              }
            }

            .desc {
              color: #E62E2E;
              margin: 5px 0px 15px 0px;
              width: 100%;
              display: block;
              font-size: 18px;
            }

            .word {
              display: flex;
              flex-direction: column;
              gap: 30px;

              .point {
                .pointHead {
                  font-weight: 400;
                  color: #667085;
                  margin-bottom: 10px
                }

                .pointBody {
                  width: 90%;

                  li {
                    color: #667085;
                    margin-left: 1.4rem;
                  }
                }
              }
            }
          }

          .mastery {
            grid-column: span 2;
          }
        }
      }

      .personality {
        .chartt {
          .ch {
            .rating {
              .level {
                color: #101323;
              }
            }
          }
        }

        .organise {
          grid-column: span 3;

          .analytical {
            .head {
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 10px 0px;
              border-bottom: 1px solid #EAECF0;
              color: #101323;
              font-size: 24px;
              font-weight: 500;

              .matchTick {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 14px;
                font-weight: 300;
                color: #3DCC87;

                svg {
                  path {
                    fill: #3DCC87;
                  }
                }
              }
            }

            .desc {
              margin-top: 15px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 3rem;

              .point {
                width: 50;

                .pointHead {
                  color: #101323;
                  font-weight: 600;
                }

                .pointBody {
                  li {
                    margin-left: 1.5rem;
                    line-height: 2;

                    .liHead {
                      color: #101323;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .domainKnowledge {
        padding: 0px 1rem;

        .question {
          border-bottom: 1px solid #F2F4F7;
          margin-top: 50px;
          margin-bottom: 50px;

          .questionHead {
            color: #101323;
            margin-bottom: 10px;
            font-weight: 400;
          }

          .questionText {
            color: #667085;
            margin-bottom: 10px;
          }
        }

        .section {
          .score {
            margin-top: 30px;
            margin-bottom: 15px;
            display: flex;
            gap: 5px;

            .scoreHead {
              color: #605BFF;
              font-weight: 400;
            }

            .scoreText {
              color: #667085;
            }

            .scoreActual {
              color: #101323;
            }
          }
        }
      }
    }

  }
}
}

.container {
  .head {
    margin-top: 100px;
    font-size: 22px;
    font-weight: 500;
    color: #101323;
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;

    .lineContainer {
      flex-grow: 1; /* This makes the container take up the remaining space */
      display: flex;
      align-items: center; 
      
      .linee {
      border-bottom: 2px solid #F2F4F7;
      height: 2px;
      width: 100%;
    }

    }
    
    .chevron {
      width: 40px;
      cursor: pointer;
      transition: transform 0.5s ease;

      path {
        stroke: #667085;
      }
    }

    .up {
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: 1300px) {
  .main {
    .candidateReport {
    .general {
      .body {
        grid-template-columns: 0.4fr 4fr 3fr;

        .candidatesDetails {
          .name {
            font-size: 40px;
          }

          .deets {
            flex-direction: column;
            gap: 5px;

            .number {
              padding-left: 0px;
            }

            .email {
              border-right: none;
              padding-right: 0px;
            }
          }
        }

      }
    }
  }
  }
  
}

@media screen and (max-width: 1050px) {
  .main {
      .candidateReport {
    .general {
      .body {
        grid-template-columns: 2fr 4fr 2fr;
      }
    }

    .specific {
      .filter {
        overflow-x: auto;
        scrollbar-width: none;

        .filterTab {
          min-width: 1000px;
        }
      }

      .basic {

        .scroll {
          overflow-x: auto;
          scrollbar-width: none;
          width: 100%;

          .basicExpectations {
            min-width: 1000px;

            .chartt {
              .dough {
                width: 90%;
              }
            }
          }
        }



        .softSkills {
          grid-template-columns: 1fr;
          gap: 0rem;

          .chartt {
            flex-direction: row;

            .dough {
              width: 40%;
            }

            .ch {
              gap: 30px;
            }
          }
        }

        .personality {
          grid-template-columns: 1fr;

          .organise {
            margin-top: 40px;
            grid-column: auto;
          }
        }
      }

    }
  }
  }


}

@media screen and (max-width: 950px) {
  .main {
      .candidateReport {
    .general {
      .body {
        grid-template-columns: 1fr 2fr;

        .candidatesDetails {
          .btns {
            flex-direction: column;
          }
        }

        .rBtn {
          margin-top: 20px;
          grid-column: span 2;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding-right: 3.5rem;

          .reportBtn {
            width: 56%;

          }
        }
      }
    }

    .footer {
      .foot {
        width: 80%;

        .left {
          .rejectBtn {
            padding: 8px 15px;
            font-size: 14px;
          }
        }
      }
    }
  }
  }

}

@media screen and (max-width: 650px) {
  .main {
      .candidateReport {
    .general {
      .body {
        grid-template-columns: 1fr 2fr;

        .chart {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .innerChart {
            width: 99%;
          }
        }

        .candidatesDetails {
          .btns {
            flex-direction: column;
          }
        }

        .rBtn {
          margin-top: 20px;
          grid-column: span 2;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding-right: 2.5rem;

          .reportBtn {
            width: 58%;

          }
        }

      }
    }
  }
  }

}

@media screen and (max-width: 550px) {
  .main {
      .candidateReport {
    .general {
      .body {
        grid-template-columns: 1fr;

        .chart {
          .innerChart {
            width: 60%;
          }
        }

        .rBtn {
          grid-column: auto;
          justify-content: center;
          padding-right: 0px;

          .reportBtn {
            width: 80%;
          }
        }
      }
    }

    .specific {
      .basic {
        .workspaceQualities {
          .rightSide {
            .half {
              .analytical {
                .desc {
                  grid-template-columns: 1fr !important;
                }
              }
            }
          }
        }

        .workMotivators {
          .desc {
            .analytical {
              grid-column: span 2;
            }
          }
        }

        .personality {
          .desc {
            grid-template-columns: 1fr !important;
          }
        }

        .domainKnowledge {
          padding: 0px !important;

          .question {
            margin-bottom: 20px;
          }

          .section {
            .score {
              margin-top: 15px;
              margin-bottom: 7px;
            }
          }

          .stackSection {
            padding: 10px 0.5rem;
            margin-bottom: 20px;

            .stack {
              .left {
                gap: 1rem;

                .chart {
                  width: 80px;
                }

                .deets {
                  gap: 5px;

                  .stackText {
                    font-size: 14;
                  }
                }
              }
            }

            .question {
              font-size: 14px;

              .questionProp {
                margin-bottom: 5px;
              }

              .options {
                .option {
                  padding: 5px 10px;
                  border-radius: 5px;
                  margin: 6px 0px;

                  .opt {
                    gap: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }

}