@mixin flex-center($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-style($color, $font-weight, $cursor: auto) {
  color: $color;
  font-weight: $font-weight;
  cursor: $cursor;
}

@mixin button($gap, $font-size, $font-weight, $padding) {
  gap: $gap;
  font-size: $font-size;
  font-weight: $font-weight;
  padding: $padding;
}

// * {
//   border: 1px solid;
// }

.overview {
  margin-top: -40px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
    margin-bottom: 20px;

    .left {
      .smallHead {
        font-size: 16px;
        color: #101323;
        font-weight: 400;
      }

      .head {
        font-size: 22px;
        color: #101323;
        font-weight: 500;
      }

      .subHead {
        font-size: 14px;
        font-weight: 300;
        color: #667085;
        margin-top: 7px;
      }
    }

    .rightCurrency {
      display: flex;
      align-items: center;
      gap: 15px;
      background: #F9FAFB;
      border-radius: 10px;
      padding: 4px;

      .naira {
        cursor: pointer;
        padding: 12px 40px;
        border-radius: 8px;
      }

      .clicked {
        transition: all 0.3s ease-in;
        background: #FFFFFF;
        box-shadow: 0px 20px 24px -4px #10182814;
        box-shadow: 0px 8px 8px -4px #10182808;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 15px;

      .leftArrow {
        background: #F9FAFB;
        padding: 10px 10px 5px 10px;
        border-radius: 3px;
        cursor: pointer;
      }

      .rightArrow {
        background: #F9FAFB;
        padding: 10px 10px 5px 10px;
        transform: rotate(180deg);
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .filterTabs {
      margin-top: 20px;
    }
  }

  .cards {
    .cardScroll {
      margin-top: 30px;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      scroll-behavior: smooth;
      gap: 1rem;


      .card {
        flex: 0 0 auto;
        width: calc((100% / 4) - (3 / 4 * 1rem));
        position: relative;
      }

      .head {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .cardScroll::-webkit-scrollbar {
      width: 0;
    }
  }

  .bigCards {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    margin: 30px 0px 15px 0px;

    .chartt {
      border: 1px solid #EAECF0;
      border-radius: 10px;

      .chartHead {
        padding: 16px 20px;
        border-bottom: 1px solid #EAEBF0;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
        color: #101323;
      }

      .chartLine {
        border-top: 1px solid #D0D5DD;
        display: flex;
        gap: 20px;
        width: 95%;
        margin: 0 auto;

        .active {
          color: #605BFF;
          border-top: 1px solid #605BFF;
        }

        span {
          font-size: 16px;
          font-weight: 400;
          padding: 15px 0px;
          color: #101323;
          cursor: pointer;
          margin-top: -1px;
        }

        span:hover {
          color: #605BFF;
        }
      }
    }

    .charttt {
      border: none;

      .chartGraph {
        .lineChart {
          height: 430px;
        }
      }
    }

    .left {
      border: 1px solid #EAECF0;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .headBar {
        padding: 16px 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .headd {
          color: #101323;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .chart {
        width: 70%;
        margin: 20px 0px
      }

      .chartLine {
        border-top: 1px solid #D0D5DD;
        display: flex;
        gap: 20px;
        width: 95%;
        margin: 0 auto;

        .active {
          color: #605BFF;
          border-top: 1px solid #605BFF;
        }

        span {
          font-size: 16px;
          font-weight: 400;
          padding: 15px 0px;
          color: #101323;
          cursor: pointer;
          margin-top: -1px;
        }

        span:hover {
          color: #605BFF;
        }
      }
    }

    .right {
      .headBar {
        border-bottom: 1px solid #EAECF0;

        .select {
          right: 2rem;

          div {
            padding: 6px 40px 6px 10px;
          }

          div:hover {
            background-color: #F2F4F7;
            color: #101323;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        width: 100%;

        .bodyBar {
          padding: 18px 20px;
          display: flex;
          justify-content: space-between;
          width: 100%;

          .title {
            color: #101323;
            font-size: 14px;
            font-weight: 500;
            max-width: 210px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

        }
      }
    }

    .candidateCards {
      height: 424px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      scrollbar-width: none;
      scroll-behavior: smooth;
      gap: 0.6rem;



      .card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #EAECF0;
        border-radius: 8px;
        padding: 12px 17px;
        cursor: pointer;

        .leftt {
          display: flex;
          align-items: center;
          gap: 1rem;

          .icon {
            padding: 8px 8px 4px 8px;
            border-radius: 5px;
            background: #605BFF;
            width: fit-content;
            height: fit-content;
          }

          .texts {
            display: flex;
            flex-direction: column;

            .textTitle {
              max-width: 100px;
              font-size: 12px;
              color: #667085;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .textNo {
              margin-top: 5px;
              display: flex;
              gap: 4px;
              align-items: flex-start;

              .headTextt {
                color: #101323;
                font-size: 20px;
                font-weight: 600;
                margin-top: 5px;
              }

              .iconn {
                width: 15px;
              }

              .evenIcon {
                path {
                  fill: #605BFF;
                }
              }

              span {
                font-size: 12px;
              }

              .positive {
                color: #18B368;
              }
              .even {
                color: #605BFF;
              }

              .negative {
                color: #E62E2E;
              }
            }
          }
        }

        .rate {
          display: flex;
          align-items: center;
          gap: 8px;

          .view {
            display: flex;
            background: rgba(229, 225, 252, 0.4);
            gap: 4px;
            border-radius: 5px;
            padding: 2px 8px;

            .viewDesc {
              font-size: 13px;
              color: #605BFF;
            }

            .viewArrow {
              path {
                stroke: #605BFF;
              }
            }
          }

        }

      }

      .clickedd {
        border: 1px solid #605BFF;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .overview {
    .employerSelected {
      left: 1.8rem;
    }
  }

}

@media screen and (max-width: 900px) {
  .overview {
    .header {
      .left {
        .head {
          font-size: 20px;
        }

        .subHead {
          font-size: 12px;
        }
      }

      .right {
        gap: 10px;
      }
    }

    .bigCards {
      .left {
        .headBar {
          .headd {
            font-size: 14px;
          }
        }

        .chart {
          margin: 10px 0px;
        }

        .foot {
          margin-top: 40px;
          gap: 10%;

          .leftFoot {
            .new {
              padding: 6px;

              .newArrow {
                width: 10px;
              }
            }

            .newDesc {
              font-size: 16px;

              .cont {
                font-size: 12px;
              }
            }
          }
        }
      }

      .right {
        .body {
          .bodyBar {
            padding: 12px 20px;

            .title {
              max-width: 160px;
              font-size: 13px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 800px) {
  .overview {
    .bigCards {
      .left {
        .chartLine {
          gap: 8%;

          span {
            font-size: 14px;
          }
        }
      }
    }

    .cards {
      .cardScroll {
        .card {
          width: calc((100% / 3) - (2 / 3 * 1rem));
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .overview {
    .header {
      .left {
        .head {
          font-size: 18px;
        }

        .subHead {
          font-size: 12px;
        }
      }

      .right {
        gap: 10px;

        .leftArrow {
          padding: 3px 3px 0px 3px;

          svg {
            width: 12px;
            height: auto;
          }
        }

        .rightArrow {
          padding: 3px 3px 0px 3px;

          svg {
            width: 12px;
            height: auto;
          }
        }
      }
    }

    .cards {
      .cardScroll {
        .card {
          width: calc((100% / 3) - (2 / 3 * 1rem));
        }
      }
    }

    .bigCards {

      .left {
        .chart {
          width: 60%;
        }

        .foot {
          margin-top: 40px;
          gap: 20%;

          .leftFoot {
            .newDesc {
              font-size: 14px;

              .cont {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .overview {
    .header {
      .rightCurrency {
        .naira {
          padding: 8px 30px;
        }
      }
    }

    .tabs {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      .filterTabs {
        margin-top: 10px;
        width: 90%;
        
        button {
          width: 25%;
        }
      }

      .dateFilter {
        width: 90%;
      }
    }

    .bigCards {
      grid-template-columns: 1fr;

      .left {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 50% 50%;

        .headBar {
          grid-column: 1 / -1;
        }

        .chart {
          margin: 20px 5%;
          display: flex;
          justify-content: center;
          width: 90%;
          grid-column: 1 / 2;
        }

        .chartLine {
          width: 100%;
          gap: 5%;
          grid-column: 2 / 3;
        }

        .foot {
          flex-direction: column;
          gap: 30px;
          align-items: flex-end;
          justify-content: center;
          margin-top: 0px;
        }
      }

      .right {
        grid-template-columns: 1fr;

        .body {
          .bodyBar {
            .title {
              max-width: 250px;
            }
          }
        }
      }
    }

    .cards {
      .cardScroll {
        margin-top: 20px;

        .card {
          width: calc((100% / 2) - (1 / 2 * 1rem));
        }
      }
    }

    .employerSelected {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .buttons {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .overview {
    .header {
      flex-direction: column;

      .rightCurrency {
        margin-top: 1.5rem;
      }

      .right {
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;

        .leftArrow {
          padding: 3px 10px 0px 10px;

          svg {
            width: 15px;
            height: auto;
          }
        }

        .rightArrow {
          padding: 3px 10px 0px 10px;

          svg {
            width: 15px;
            height: auto;
          }
        }
      }
    }

    .bigCards {
      .left {
        .foot {
          .leftFoot {
            .newDesc {
              font-size: 12px;
              gap: 2px;
            }
          }
        }
      }
    }

    .cards {
      .cardScroll {
        margin-top: 20px;

        .card {
          width: calc((100% / 1) - (1 * 1rem));
        }
      }
    }
  }
}