.inputDropDown {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 15px;

  .planDetails {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .planName {
      display: flex;
      align-items: center;
      gap: 10px;

      .name {
        color: #667085;
        font-weight: 300;
        font-size: 14px;
      }
    }

    .chevron {
      cursor: pointer;
      transition: transform 0.5s ease;
    }

    .up {
      transform: rotate(180deg);
    }
  }

  .itemWrap {
    transition: transform 0.3s ease;

    .userDropdown {
      .item {
        display: flex;
        gap: 10px;
        font-size: 13px;
        color: #667085;
        padding: 10px 0px 10px 0px;
      }

      .firstItem {
        margin-top: 15px;
      }
    }

    .userDropdown:hover,
    .activeItem {
      cursor: pointer;
    }

    .activeItem {
      color: #fff;

      .sidebarType {
        .nameIcon {
          .sidebarIcon {
            path {
              fill: #FFFFFF;
            }
          }
        }
      }
    }
  }

  .candidatesWrap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .showPlans {
    transition: max-height 0.5s ease;
    overflow: hidden;
    max-height: 500px;
  }

  .hidePlans {
    transition: max-height 0.5s ease;
    overflow: hidden;
    max-height: 0;
  }

  .inputWrap {
    .inputs {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 25px 0px 5px 0px;

      .input {
        margin-bottom: 0;
      }

      .dash {
        border: 1px solid black;
      }
    }
  }
}
