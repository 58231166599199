.profileInfo {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;

  .contact {
    .head {
      color: #101323;
      font-size: 16px;
      font-weight: 400;
    }

    .contactPfp {
      margin: 30px 0px 20px 0px;
      width: 65px;
      height: 65px;
    }

    .name {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    .inputContainer {
      margin-bottom: 1.5rem;

      label {
        font-weight: 300;
        color: #101323;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }

    .input::placeholder {
      color: #667085;
      font-weight: 300;
      font-size: 16px;
    }
    .input {
      color: #101323;
      font-weight: 300;
      font-size: 16px;
    }

    .select [class*="formSelect__control"] {
      border: 1px solid #dae0e6;
      border-radius: 8px;
      cursor: pointer;
    }

    .select [class*="formSelect__placeholder"] {
      color: #667085;
      font-weight: 300;
      font-size: 16px;
    }
  }

}

@media screen and (max-width: 600px) {

    .profileInfo {
      grid-template-columns: 1fr;
    }
}