.filter {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #e2e5ea;
  border-radius: 8px;
  padding: 8px 15px;
  cursor: pointer;

  .filterIcon {
    
  }
  .text {
    font-size: 13px;
  }
}

.afterFilter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    cursor: pointer;
  }
  .filterRange {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    .close {
      path {
        fill: #667085;
      }
    }
  }
}