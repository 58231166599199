.email {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email::-webkit-scrollbar {
  width: 0px;
}
.name {
  max-width: 200px;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}
.name::-webkit-scrollbar {
  width: 0px;
}

.doughnut {
  width: 50px;
  height: 50px;
}

.statusSection {
  .status {
    display: flex;
    gap: 4px;
    padding: 2px 5px;
    border-radius: 5px;

    .dotClosed {
      rect {
        fill: #E62E2E;
      }
    }

    .dotScheduled {
      rect {
        fill: #DB8400;
      }
    }
  }

  .textClosed {
    background: #FFF2F0;
    color: #E62E2E;
  }

  .textScheduled {
    background: #FFF5E6;
    color: #EEA23E;
  }

  .textActive {
    background: rgba(230, 255, 243, 0.7);
    color: rgba(57, 205, 98, 1);
  }
}

.selectIcon {
  display: flex;
  justify-content: center;
}

.tableBody {
  padding: 0;
}

@media screen and (max-width: 900px) {
  .email {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  }