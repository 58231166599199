.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;

  .container {
    width: 410px;
    .head {
      margin-bottom: 1rem;
      .logo {
        height: auto;
        width: 160px;
      }
    }

    .ttl {
      font-family: 'Darker Grotesque';
      color: #101323;
      font-weight: 600;
      font-size: 32px;
      margin-bottom: 1rem;
    }
    .subttl {
      font-size: 16px;
      font-weight: 300;
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 12px;
      width: 100%;
      max-width: 500px;
      margin-top: 3rem;

      .form {
        width: 100%;

        .input {
          box-shadow: 0px 1px 2px 0px #1018280A;
        }
        .mainInput {
          font-size: 14px;
        }        
        .passwordInput {
          margin-bottom: 0.3rem;
          box-shadow: 0px 1px 2px 0px #1018280A;
        }

        .footer {
          font-family: 'Darker Grotesque';
          font-weight: 400;
          font-size: 16px;
        }

        .continue {
          width: 100%;
          height: 56px;
          font-size: 16px;
          font-weight: 300;
          margin-top: 1.6rem;
          margin-bottom: 2rem;
          border-radius: 4px;
        }

        .input {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .page {
    .container {
      width: 350px;
      .head {
        .logo {
          height: auto;
          width: 140px;
        }
      }

      .body {
        .ttl {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .page {
    .container {
      width: 300px;

      .body {
        .ttl {
          font-size: 16px;
        }
      }
    }
  }
}