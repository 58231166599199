.filterList {
  display: flex;
  overflow: auto;
  background: var(--bg-image);
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #D0D5DD;

  button {
    border: none;
    padding: 10px 14px;
    white-space: nowrap;
    background: transparent;
    font-size: 14px;
    border-right: 1px solid #D0D5DD;
    color: #667085;
    font-weight: 400;

    &:last-of-type {
      border: none;
    }
  }

  .active {
    background: #f1f3f6;
    color: #101323;
  }
}

@media screen and (max-width: 600px) {
  .filterList {
    button {
      font-size: 12px;
    }
  }

}