.sort {
  color: #667085;
  font-weight: 500;
  box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.04);
  transition: transform 0.5s ease;

  .sortList {
    box-shadow: 0px 25px 25px -8px rgba(16, 24, 40, 0.1);
    border-radius: 6px;
    position: absolute;
    background: #FFFFFF;
    z-index: 9999;
    transition: transform 0.3s ease;
    right: 0rem;

    .item:hover {
      background-color: #F2F4F7;
    }
  
    .item {
      font-size: 13px;
      font-weight: 300;
      padding: 12px 60px 12px 15px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 7px;
      cursor: pointer;

      svg {
        path {
          fill: #667085;
        }
      }
    }
  }
  .sortHd {
    transition: background-color 0.6s ease, padding 0.6s ease, border-radius 0.6s ease;
  }
  .select {
    border-radius: 3px;
    padding: 0px 3px 3px 3px;
    background-color: #101323;
    transition: background-color 0.6s ease, padding 0.6s ease, border-radius 0.6s ease;
    svg {
      path {
        stroke: #FFFFFF;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .sort {
    .sortList {
      padding: 10px 10px 0px 10px;
      .item {
        font-size: 11px;
        margin-bottom: 10px;
      }
    }
    .sortHd {
      svg {
        width: 14px;
      }
    }
  }
}