.dropDown {
  background: #FAFAFB;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  .dropDownHead {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headText {
      color: #101323;
      font-weight: 400;
    }

    .chevron {
      width: 17px;
      height: 17px;
      transition: transform 0.5s ease;
      cursor: pointer;
      transform: rotate(180deg);
    }

    .up {
      transform: rotate(360deg);
    }
  }

  .dropDownBody {
    color: #667085;
    line-height: 1.8;
  }
}