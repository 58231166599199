.groupTab {
  .bigHead {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: baseline;

    .header {
      display: flex;
      flex-direction: column;

      .head {
        font-size: 22px;
        color: #101323;
        font-weight: 500;
      }

      .subHead {
        font-size: 14px;
        font-weight: 300;
        color: #667085;
        margin-top: 7px;
      }
    }

    .back {
      cursor: pointer;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #F9FAFB;
      border-radius: 7px;
    }
  }


  .filter {
    .filterTab {
      margin-top: 20px;
      border-radius: 8px;
      padding: 4px 8px;
      background: #F9FAFB;
      display: flex;
      width: fit-content;
      gap: 0.5rem;

      .filterBtn {
        padding: 10px 35px;
        color: #101323;
        font-weight: 300;
        font-size: 16px;
        cursor: pointer;
      }

      .filterBtn:hover {
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
        background: #FFFFFF;
        border-radius: 8px;
        font-weight: 400;
      }

      .active {
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
        background: #FFFFFF;
        border-radius: 8px;
        font-weight: 400;
      }

    }
  }
}

@media screen and (max-width: 600px) {
  .groupTab {
    .filter {
      .filterTab {
        width: 100%;
        justify-content: space-between;

        .filterBtn {
          padding: 6px 5%;
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .groupTab {
    .filter {
      overflow-x: auto;
      scrollbar-width: none;

      .filterTab {
        min-width: 400px;
      }
    }

  }
}