@mixin flex-center($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-style($color, $font-weight, $cursor: auto) {
  color: $color;
  font-weight: $font-weight;
  cursor: $cursor;
}

@mixin button($gap, $font-size, $font-weight, $padding) {
  gap: $gap;
  font-size: $font-size;
  font-weight: $font-weight;
  padding: $padding;
}

.employers {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
      .head {
        font-size: 22px;
        color: #101323;
        font-weight: 500;
      }

      .subHead {
        font-size: 14px;
        font-weight: 300;
        color: #667085;
        margin-top: 7px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 15px;

      .leftArrow {
        background: #F9FAFB;
        padding: 10px 10px 5px 10px;
        border-radius: 3px;
        cursor: pointer;
      }

      .rightArrow {
        background: #F9FAFB;
        padding: 10px 10px 5px 10px;
        transform: rotate(180deg);
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .filterTabs {
      margin-top: 20px;
    }
  }

  .cards {
    .cardScroll {
      margin-top: 30px;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      scroll-behavior: smooth;
      gap: 1rem;

      .card {
        flex: 0 0 auto;
        width: calc((100% / 4) - (3 / 4 * 1rem));
      }
    }
  }

  .table {
    margin-top: 30px;
    border: 1px solid #EAECF0;
    border-radius: 10px;

    .tableBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.2rem 1rem 1.2rem;

      .head {
        color: #101323;
        font-size: 16px;
        font-weight: 400;
      }

      .tableActions {
        display: flex;
        gap: 1rem;

        .filterScroll {
          max-width: 500px;

          .filter {
            display: flex;
            align-items: center;
            overflow-x: scroll;
            scroll-behavior: smooth;
            gap: 1rem;

            .filterRange {
              p {
                white-space: nowrap;
              }
            }
          }
        }

        .selectContainer {
          display: flex;
          align-items: center;
          position: relative;

          .prev {
            cursor: pointer;
            transition: padding 0.6s ease, border-radius 0.6s ease;

            svg {
              width: 13px;
            }
          }

          .selectIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 9px 3px;
            border-radius: 5px;
            background-color: #101323;
            transition: padding 0.6s ease, border-radius 0.6s ease;
            cursor: pointer;

            svg {
              width: 13px;

              path {
                stroke: #FFFFFF;
              }
            }
          }

          .tableEdit {
            box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
            border-radius: 6px;
            width: 380px;
            position: absolute;
            background: #FFFFFF;
            z-index: 9999;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease;
            right: -15px;
            top: 50px;

            .headerr {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 30px;

              .titles {
                .top {
                  color: #101323;
                  font-family: 'Darker Grotesque';
                  font-size: 18px;
                  line-height: 1;
                  height: fit-content;
                  font-weight: 700;
                  margin-top: -6px;
                }

                .sub {
                  margin-top: 5px;
                }
              }

              .close {
                border: 1px solid #101323;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                cursor: pointer;
                width: fit-content;
                height: fit-content;

                svg {
                  width: 6px;
                  height: 6px;

                  path {
                    fill: #101323;
                  }
                }
              }
            }

            @mixin margin-bottom($margin) {
              margin-bottom: $margin;
            }

            .bodyHead {
              width: 92%;
              @include flex-center(space-between, center);
              @include margin-bottom(15px);

              .show {
                @include text-style(#101323, 300);
              }

              .hide {
                @include text-style(#605BFF, 400, pointer);
              }
            }

            .bodyContent {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 25px;
              padding: 0px 3% 20px 3%;
              border-bottom: 2px solid #F2F4F7;
              position: relative;

              .row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                user-select: none;

                .name {
                  display: flex;
                  align-items: center;
                  gap: 15px;

                  .dragHandle {
                    cursor: grab;
                  }

                  .selects {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: fit-content;
                    padding: 0px;



                    svg {

                      path {
                        stroke: #D0D5DD;
                      }
                    }
                  }

                  .headerName {
                    color: #101323;
                    font-size: 14px;
                    font-weight: 300;
                  }
                }

                .eye {
                  cursor: pointer;
                }
              }

              .none {
                border: 1px solid;
                display: none;
              }
            }

            .hidden {
              border-bottom: none;

              .row {
                .name {
                  .headerName {
                    color: #667085;
                  }
                }
              }
            }

            .hiddenHead {
              margin-top: 25px;
            }
          }
        }
      }
    }

    .emptyTable {
      width: 100%;
      // border: 1px solid;
      display: flex;
      justify-content: center;
    }

    .tableBodyItem {
      font-size: 13px;
      font-weight: 300;
      color: #667085;
      padding: 0.5rem 1.2rem 0.5rem 1.2rem;
    }

    .tableItem {
      display: flex;
      align-items: center;
      word-wrap: break-word;
      word-break: break-all;
    }

  }

  .employerSelected {
    padding: 8px 16px;
    background: #101323;
    border-radius: 8px;
    margin-top: 20px;
    position: fixed;
    left: calc(256px + 1.8rem);
    right: 1.8rem;
    margin: 0 auto;
    bottom: 10px;

    @include flex-center(space-between, center);

    .amount {
      color: #FFFFFF;
      font-weight: 300;
    }

    .buttons {
      @include flex-center(center, center);
      gap: 10px;

      .requestBtn {
        background: #FFFFFF;
        border: none;
        color: #1D2939;
        @include button(6px, 14px, 300, 4px 8px);
      }

      .deleteBtn {
        @include button(6px, 14px, 300, 4px 8px);
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .employers {
    .employerSelected {
      left: 1.8rem;
    }
  }

}

@media screen and (max-width: 900px) {
  .employers {
    .header {
      .left {
        .head {
          font-size: 20px;
        }

        .subHead {
          font-size: 12px;
        }
      }

      .right {
        gap: 10px;
      }
    }

    .cards {
      .cardScroll {}
    }

    .table {
      .tableBar {
        .tableActions {
          .filterScroll {
            max-width: 250px;

            .filter {
              display: flex;
              align-items: center;
              overflow-x: scroll;
              scroll-behavior: smooth;
              gap: 1rem;

              .filterRange {
                p {
                  white-space: nowrap;
                }
              }
            }
          }

          .selectContainer {
            .tableEdit {
              padding: 15px;

              .headerr {
                margin-bottom: 20px;

                .titles {
                  .top {
                    font-size: 17px;
                  }

                  .sub {
                    font-size: 13px;
                  }
                }
              }

              .bodyContent {
                .row {
                  .name {
                    .headerName {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .paginationBtn {
        padding: 5px 8px;
        font-size: 12px;
      }

      .paginText {
        font-size: 12px;
        gap: 1rem !important;
      }

      .pagin {
        padding: 3px 0.3rem;
      }
    }

    .employerSelected {
      left: 1.2rem;
      right: 1.2rem;
    }
  }
}

@media screen and (max-width: 650px) {
  .employers {
    .header {
      .left {
        .head {
          font-size: 18px;
        }

        .subHead {
          font-size: 12px;
        }
      }

      .right {
        gap: 10px;

        .leftArrow {
          padding: 3px 3px 0px 3px;

          svg {
            width: 12px;
            height: auto;
          }
        }

        .rightArrow {
          padding: 3px 3px 0px 3px;

          svg {
            width: 12px;
            height: auto;
          }
        }
      }
    }

    .table {
      .tableBar {
        padding: 0.6rem 1.2rem;

        .head {
          font-size: 14px;
        }

        .tableActions {
          align-items: center;
          gap: 0.5rem;

          .filterScroll {
            max-width: 160px;
          }

          .selectContainer {
            .tableEdit {
              width: 250px;

              .headerr {
                margin-bottom: 20px;

                .titles {
                  .top {
                    font-size: 14px;
                    margin-top: 0px;
                  }

                  .sub {
                    font-size: 12px;
                    margin-top: 0px;
                  }
                }

                .close {
                  padding: 3px;
                }
              }

              .bodyHead {
                margin-bottom: 10px;
                width: 95%;

                .show {
                  font-size: 13px;
                }

                .hide {
                  font-size: 12px;
                }
              }

              .bodyContent {
                gap: 12px;

                .row {
                  .name {
                    gap: 5px;
                  }

                  .dragHandle {
                    svg {
                      height: 13px;
                    }
                  }

                  .eye {
                    width: 12px;
                    height: auto;
                  }
                }
              }
            }
          }

          .search {
            padding: 9.5px 15px;
            height: fit-content;

            svg {
              width: 12px;
              min-width: 12px;
            }

            input {
              font-size: 11px;
              width: 5rem;
              height: 10px;
            }

            input::placeholder {
              font-size: 11px;
            }
          }

          .select {
            width: 12px;
          }
        }
      }

      .pagin {
        padding: 2px 0.35rem;
      }

      .paginationBtn {
        padding: 5px 8px;
        font-size: 12px;
      }
    }

    .employerSelected {

      .amount {
        font-size: 12px;
      }

      .buttons {
        .requestBtn {
          @include button(6px, 12px, 300, 4px 8px);

          svg {
            width: 10px;
            height: auto;
            margin-top: -2px;
          }
        }

        .deleteBtn {
          @include button(6px, 12px, 300, 4px 8px);

          svg {
            width: 10px;
            height: auto;
            margin-top: -2px;
          }
        }
      }
    }

    .cards {
      .cardScroll {
        .card {
          width: calc((100% / 3) - (2 / 3 * 1rem));
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .employers {
    .tabs {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      .filterTabs {
        margin-top: 10px;
        width: 80%;
        
        button {
          width: 33.3333%;
        }
      }

      .dateFilter {
        width: 80%;
      }
    }
  }

}

@media screen and (max-width: 480px) {
  .employers {
    .header {
      flex-direction: column;

      .right {
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;

        .leftArrow {
          padding: 3px 10px 0px 10px;

          svg {
            width: 15px;
            height: auto;
          }
        }

        .rightArrow {
          padding: 3px 10px 0px 10px;

          svg {
            width: 15px;
            height: auto;
          }
        }
      }
    }

    .cards {
      .cardScroll {
        margin-top: 20px;

        .card {
          width: calc((100% / 2) - (1 / 2 * 1rem));
        }
      }
    }

    .table {
      .tableBar {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        .tableActions {
          justify-content: space-between;
          width: 100%;
          .selectContainer {
            .tableEdit {
              .heaaderr {
                display: flex;
                flex-direction: row !important;
              }
            }
          }
        }
      }
    }

    .employerSelected {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .buttons {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}