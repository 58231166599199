@mixin flex-center($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-style($color, $font-weight, $cursor: auto) {
  color: $color;
  font-weight: $font-weight;
  cursor: $cursor;
}

.main {
  .reports {
  .header {
    font-size: 22px;
    margin-top: 30px;
    color: #101323;
    font-weight: 500;
  }

  .filterTab {
    border-radius: 8px;
    padding: 4px 8px;
    background: #F9FAFB;
    display: flex;
    width: fit-content;
    gap: 0.5rem;

    .filterBtn {
      padding: 7px 25px;
      color: #101323;
      font-weight: 300;
      font-size: 14px;
      cursor: pointer;
    }

    .filterBtn:hover {
      // box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      background: #FFFFFF;
      border-radius: 8px;
      font-weight: 400;
    }

    .active {
      // box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      background: #FFFFFF;
      border-radius: 8px;
      font-weight: 400;
    }

  }

  .table {

    .tableBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2.5rem 0rem 1.5rem 0rem;

      .head {
        color: #101323;
        font-size: 16px;
        font-weight: 400;
      }

      .tableActions {
        display: flex;
        gap: 1rem;

        .filterScroll {
          max-width: 200px;

          .filter {
            display: flex;
            align-items: center;
            overflow-x: scroll;
            scroll-behavior: smooth;
            gap: 1rem;

            .filterRange {
              p {
                white-space: nowrap;
              }
            }
          }
        }

        .selectContainer {
          display: flex;
          align-items: center;
          position: relative;

          .prev {
            cursor: pointer;
            transition: padding 0.6s ease, border-radius 0.6s ease;

            svg {
              width: 13px;
            }
          }

          .selectIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 9px 3px;
            border-radius: 5px;
            background-color: #101323;
            transition: padding 0.6s ease, border-radius 0.6s ease;
            cursor: pointer;

            svg {
              width: 13px;

              path {
                stroke: #FFFFFF;
              }
            }
          }
        }
      }
    }

    .singleReport {
      border: 1px solid #EAECF0;
      border-radius: 10px;

      .emptyTable {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .tableWrap {
        min-width: 500px;
      }

      .tableItem {
        display: flex;
        align-items: center;
      }

      .reportTableHeader {
        grid-template-columns: 1.5fr 1.3fr 1.3fr 1fr 0.5fr 0.2fr 0.2fr;
        border-top: none;
        background-color: none;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        gap: 0px;
      }

      .reportTableBodyItem {
        font-size: 13px;
        font-weight: 300;
        color: #667085;
        padding: 6px 1.2rem 6px 1.2rem;
        grid-template-columns: 1.5fr 1.3fr 1.3fr 1fr 0.5fr 0.2fr 0.2fr;
        gap: 0px;
      }

      .headerStyle:nth-last-of-type(3) {
        gap: 10px;
      }

      .headerStyle:nth-last-of-type(1),
      .headerStyle:nth-last-of-type(2) {
        visibility: hidden;
      }

      .paginationBtn {
        background: none;
        color: #667085;
      }
    }
  }
}
}


@media screen and (max-width: 950px) {
  .main {
     .reports {
    .table {
      .tableBar {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        .tableActions {
        .filterScroll {
          max-width: 400px;
        }
        }
      }
    }
  } 
  }

}

@media screen and (max-width: 650px) {
  .main {
      .reports {
    .table {
      .tableBar {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
        padding: 1rem 0rem 1rem 0rem;

        .tableActions {
          align-items: center;

          .search {
            padding: 10px 10px;
            height: fit-content;

            svg {
              width: 12px;
              min-width: 12px;
            }

            input {
              font-size: 12px;
              width: 5rem;
              height: 10px;
            }

            input::placeholder {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  }

}

@media screen and (max-width: 590px) {
  .main {
      .reports {
    .table {
      .tableBar {
        overflow-x: scroll;
          scroll-behavior: smooth;
        .filter {
          overflow-x: scroll;
          scroll-behavior: smooth;

          .filterTab {
            max-width: 530px;
            .filterBtn {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  }

}