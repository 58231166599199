.check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &.right {
    flex-direction: row;
  }
  &.left {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
.check input {
  display: none;
}
.mark {
  cursor: pointer;
  height: 24px; // Increase the size to make the design more visible
  width: 24px; // Increase the size to make the design more visible
  min-width: 24px;
  background-color: #ffff;
  transition: all 0.3s ease-in;
  border: 1px solid #605BFF;
  display: block;
  border-radius: 50%;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 16px; // Adjust the height to create the white space
    width: 16px; // Adjust the width to create the white space
    background: #ffff; // The white space inside
    border-radius: 50%;
    position: absolute;
    top: 50%; // Center inside the white space vertically
    left: 50%; // Center inside the white space horizontally
    transform: translate(-50%, -50%); // Center it using transform
    transition: background-color 0.3s ease-in; // Smooth transition
  }

  &::after {
    content: '';
    display: block;
    height: 18px; // Smaller than the white space
    width: 18px; // Smaller than the white space
    background: #ffff; // Initially transparent
    border-radius: 50%;
    position: absolute;
    top: 50%; // Center inside the white space vertically
    left: 50%; // Center inside the white space horizontally
    transform: translate(-50%, -50%); // Center it using transform
    transition: background-color 0.3s ease-in; // Smooth transition
  }

  &.right {
    margin-left: 1rem;
  }
  &.left {
    margin-right: 1rem;
  }
}

.check input:checked ~ .mark {
  &::after {
    background: #605BFF; // Blue fill when checked
  }
}

.dark input:checked ~ .mark {
  &::after {
    background: #605BFF; // Blue fill when checked
  }
}
