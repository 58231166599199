.tableWrapper {
  overflow: auto;
  scrollbar-width: none;
  padding: 0px;
  position: relative;
}

.tableWrapper::-webkit-scrollbar {
  width: 0;
  /* for Chrome, Safari, and Opera */
}

.mainTableContainer {
  table-layout: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 900px;
}

.tableHeader {
  display: grid;
  grid-template-columns: 3fr 1.2fr 1.2fr 1.3fr 1fr 1.5fr 1fr 0.2fr;
  width: 100%;
  padding: 0.8rem 1.2rem 0.8rem 1.2rem;
  border-top: 1px solid #EAECF0;
  border-bottom: 1px solid #EAECF0;
  position: sticky;
  top: 0; 
  background: #fff; 
  z-index: 1; 

  span {
    color: #667085;
    font-weight: 400;
    font-size: 13px;
  }

  svg {
    transform: rotate(180deg);

    path {
      stroke: #667085;
    }

  }

  .tableHeaderStyle {
    display: flex;
    // gap: 10px;
    
    .pointer {
      cursor: pointer;
    }
    .statuss {
      margin: 15px 0px 0px -170px;
    }
  }
  .tableHeaderStyle:last-of-type {
    // visibility: hidden;
    display: none;
  }

}

.tableBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0px; /* Add this padding to offset the fixed header height */
}


.tableBodyStatus {
  display: flex;
}

.tableBodyRow {
  position: relative;
  display: grid;
  grid-template-columns: 3fr 1.2fr 1.2fr 1.3fr 1fr 1.5fr 1fr 0.2fr;
  width: 100%;
  padding: 1rem 1.2rem 1rem 1.2rem;
  border-top: 1px solid #d4e4f6;
  color: var(--text-others);
  background: #fff;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: 1px solid #d4e4f6;
  }
}

.tableBody>div:hover {
  background: #fafafa;
}

.hdWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownList {
  right: 3rem;
  background: #fff;
  // min-width: 160px;
}

.dropdownListItem {
  border: none;
  padding: 12px;
}

.dropdownListItem:first-of-type {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.dropdownListItem:last-of-type {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.dropdownListItem svg {
  width: 18px;
}

.dropdownListItem:hover {
  background: var(--green);
  color: #fff;
}

.dropdownListItem:hover svg path {
  fill: var(--white);
}

@media screen and (max-width: 1200px) {
  .tableHeader {
    grid-template-columns: 3.5fr 1.2fr 1.2fr 1.3fr 0.7fr 1.5fr 1fr 0.2fr;
    }

  .tableBodyRow {
    grid-template-columns: 3.5fr 1.2fr 1.2fr 1.3fr 0.7fr 1.5fr 1fr 0.2fr;
  }
}

@media screen and (max-width: 900px) {
  .tableHeader {
    grid-template-columns: 3.5fr 1fr 1.2fr 1.3fr 0.7fr 1.5fr 1fr 0.2fr;
    gap: 10px;
  }

  .tableBodyRow {
    grid-template-columns: 3.5fr 1fr 1.2fr 1.3fr 0.7fr 1.5fr 1fr 0.2fr;
    gap: 5px;
  }
}