.btn {
  color: rgb(57, 205, 98);
}

.selectIcon {
  display: flex;
  justify-content: center;
}

.metric {
  .scrollable {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 1000px) {
  .metric {
    .scrollable {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


@media screen and (max-width: 800px) {
  .title {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    max-width: 75px;
    white-space: nowrap;
    overflow: scroll;
    text-overflow: ellipsis;
  }
  .date::-webkit-scrollbar {
    width: 0px;
  }
}