.nav {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-bottom: 15px;

  .items:last-of-type {
    .navItem {
      a {
      color: #605BFF;
    }
    }
    
  }
  .items {
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
      color: var(--text-main);
    }

    svg {

    }
    .navItem {
      display: flex;
      align-items: center;
      .navIcon {
        width: 17px;
        height: 17px;
        margin-right: 0.2rem;
        path {
          fill: #667085;
        }
      }
      a:hover {
        color: #605BFF;
      }
    }
    .chevron {
      transform: rotate(270deg);
      width: 20px;
      height: 20px;
      margin: 0 4px;
      min-width: 16px;
      path {
        stroke: #667085;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .nav {
    font-size: 12px;
    margin-bottom: 10px;
  }
}