.filter {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #e2e5ea;
  border-radius: 8px;
  padding: 8px 15px;
  cursor: pointer;
  .text {
    font-size: 13px;
  }
}

@media screen and (max-width: 650px) {
  .filter {
    padding: 6px 15px;
    .filterIcon {
      width: 15px;
    }
  }
}