@import url(../src/assets/fonts/styles.css);

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

:root {
  /* Brand Colors */
  --secondary-blue: #605BFF;
  --primary-blue: #012169;

}

body {
  margin: 0;
  color: #667085;
  font-family: "Gelion", 'Inter', sans-serif;
  font-weight: 300;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

/* Scrollbar */
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #012169;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #012169;
}

button,
input,
textarea {
  font-family: "Gelion";
  outline: none;
}

button,
[role="button"] {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

img,
svg {
  /* width: 100%; */
  height: auto;
}

input:auto-fill {
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
