.modal {
  padding: 0 0 0 16rem !important;
  background: none !important;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: end;
}


.close {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 8px 10px 6px 10px;
  margin-top: 20px;

  .closeIcon {}
}

.container {
  background: #FFFFFF;
  border-radius: 16px;
  width: 560px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 14px;

  p {
    color: #101323;
    font-family: 'Darker Grotesque';
    font-weight: 600;
    font-size: 22px;
  }

  svg {}
}

.form {
  padding: 12px 14px;

  .wrapStatus {
    display: flex;
    flex-direction: column;
    align-items:flex-start !important;
    gap: 1rem;
    .item {
      display: flex;
      gap: 1rem;
      padding: 5px 0px;
      font-size: 13px;
    }
    .firstItem {
      padding-top: 10px;
    }
  }

  .wrap {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .dash {
      margin-top: 13px;
    }

    .input {
      margin-top: 20px;
      margin-bottom: 5px;
      font-size: 13px;
    }

    .mainInput {
      font-size: 13px !important;
      font-weight: 300 !important;
    }

    .mainInput::placeholder {
      font-size: 13px;
      color: #667085;
    }

    .mainInput::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }

      .item {
        display: flex;
        gap: 1rem;
      }
  }

}

.fieldset {
  border: none;
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  gap: 12px;
  align-items: flex-start;
  margin-bottom: 12px;

  .remove {
    height: fit-content;
    margin-top: calc((44px - 20px) / 2);
  }
}

.inputWrap {
  label {
    font-weight: 600;
  }
}

.select [class*="formSelect__value-container"] {
  font-weight: 500;
}

.footer {
  padding: 1.2rem 14px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 1rem;

  .cancelbtn {
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
  }

  button {
    border-radius: 4px;
    padding: 12px 2rem;
    font-weight: 600;
  }

  button[class*="fill"] {
    font-weight: 400;
  }
}

.errorMsg {
  font-size: 12px;
  color: var(--red);
  display: block;
  margin-top: 8px;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .modal {
    padding: 0 0 0 0rem !important;
  }
}

@media screen and (max-width: 650px) {
  .modal {
    justify-content: center;
  }
  .container {
    width: 90%;

    .form {
      .plans {
        padding: 8px 12px;
        .candidatesWrap {
          .input {
            height: 25px;
          }
        }
        .itemWrap {
          .item {
            font-size: 12px;
            .checkBox {
              width: 10px;
              height: 10px;
              min-width: 10px;
            }
          }
        }
      }
    }
  }
  .footer {
    padding: 0rem 14px 1.2rem 14px;
  
    button {
      font-size: 12px;
    }
  
    button[class*="fill"] {
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 450px) {
  .container {
    width: 90%;
    border-radius: 10px;
  }
  .header {
    padding: 0.5rem 14px 0.5rem 14px;
    p {
      font-size: 18px;
    }
  }
  .form {
    .wrap {
      flex-direction: column;
      gap: 0px;

      .dash {
        margin-top: 0px !important;
      }
  
      .input {
        margin-top: 10px !important;
        margin-bottom: 0px;
        font-size: 13px;
      }
    }
  }
  .footer {
    button {
      font-size: 11px;
      padding: 5px 1rem;
    }
  }
}