.last:first-of-type {
  font-weight: 300;
  font-size: 15px;
}

.sidebarItem:hover,
.activeItem {
  border-left: 3px solid #F9F9F9;
  color: #fff;
  background: #344054;
}

.sidebarItem {
  font-size: 16px;
  color: #98A2B3;
  padding: 10px 1.3rem 10px 1.5rem;

  .sidebarType {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nameIcon {
      display: flex;
      align-items: center;

      span {
        margin-top: 2px;
      }
    }
  }

  a.sidebarType {
    color: var(--white);
    text-decoration: none;
  }

  a {
    .sidebarIcon {
      margin-right: 0.6rem;

      path {
        fill: #98A2B3;
      }
    }

    .logout {
      path {
        fill: red;
      }
    }

    .downIcon {
      transition: transform 0.3s ease;
      transform: rotate(180deg);

      path {
        stroke: #98A2B3;
        ;
      }
    }

    .up {
      transform: rotate(360deg);
    }
  }

}

.activeItem {
  border-left: 3px solid #F9F9F9;
  color: #fff;
  background: #344054;

  a {
    .downIcon {
      path {
        stroke: #FFFFFF;
      }
    }
  }

  .sidebarType {
    .nameIcon {
      .sidebarIcon {
        path {
          fill: #FFFFFF;
        }
      }
    }
  }

}

.sidebarItemWrap {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;

  .userDropdown {
    font-size: 16px;
    color: #98A2B3;
    padding: 10px 1.6rem 10px 1.2rem;
    margin-left: 1.6rem;
  }

  .userDropdown:hover,
  .activeItem {
    border-left: 3px solid #F9F9F9;
    color: #fff;
    background: #344054;
    cursor: pointer;
  }

  .activeItem {
    border-left: 3px solid #F9F9F9;
    color: #fff;
    background: #344054;

    .sidebarType {
      .nameIcon {
        .sidebarIcon {
          path {
            fill: #FFFFFF;
          }
        }
      }
    }
  }

}

.header {
  font-size: 14px;
  color: var(--text-others);
  font-weight: 500;
  margin-bottom: 0;
  padding: 0.4rem 2rem 0.4rem 0.8rem;
}

.header:hover {
  border-left: none;
  background: none;
  color: var(--text-others);
}