.modal {
  max-width: 400px;
  position: relative;
  transform: translateX(120px);
  .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .name {
      font-weight: 700;
      color: #101323;
      font-family: 'Darker Grotesque';
    }
    .cancel {
      transform: rotate(45deg);
      cursor: pointer;
      height: 18px;
      width: 18px;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    
    .learnText {
      font-size: 13px;
    }
  }
  .buttons {
    margin-top: 10px;
    display: flex;
    .btns {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      .cancelBtn {
        border: 1px solid #D0D5DD;
        padding: 10px 20px;
        color: #101323;
        font-size: 13px;
        background: #FFFFFF;
      }
      .deleteBtn {
        padding: 10px 20px;
        border: none;
        font-size: 13px;

      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .modal {
    transform: translateX(0px);
    margin-top: auto;
  }
}