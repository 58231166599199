@mixin flex-center($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-style($color, $font-weight, $cursor: auto) {
  color: $color;
  font-weight: $font-weight;
  cursor: $cursor;
}

@mixin button($gap, $font-size, $font-weight, $padding) {
  gap: $gap;
  font-size: $font-size;
  font-weight: $font-weight;
  padding: $padding;
}
.candidates {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
      .head {
        font-size: 22px;
        color: #101323;
        font-weight: 500;
      }

      .subHead {
        font-size: 14px;
        font-weight: 300;
        color: #667085;
        margin-top: 7px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 15px;

      .leftArrow {
        background: #F9FAFB;
        padding: 10px 10px 5px 10px;
        border-radius: 3px;
        cursor: pointer;
      }

      .rightArrow {
        background: #F9FAFB;
        padding: 10px 10px 5px 10px;
        transform: rotate(180deg);
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
  .filterTabs {
    margin-top: 20px;
  }  
  .cards {
    .cardScroll {
      margin-top: 30px;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      scroll-behavior: smooth;
      gap: 1rem;

      .card {
        flex: 0 0 auto;
        width: calc((100% / 4) - (3 / 4 * 1rem));
      }
    }
  }

  .bigCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 30px 0px 15px 0px;
    .left {
      border: 1px solid #EAECF0;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .headBar {
        padding: 16px 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .headd {
          color: #101323;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .chart {
        width: 40%;
        margin: 20px 0px
      }
      .foot {
        display: flex;
        justify-content: center;
        width: 60%;
        gap: 5rem;
        margin: 20px 0px;
        .leftFoot {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          .new {
            background: #F5FAFF;
            display: flex;
            gap: 4px;
            border-radius: 50%;
            padding: 10px;
            .evenArrow {
              width: 14px;
              transform: rotate(90deg);
              path {
                stroke: #0081CC;
                stroke-width: 0.6;
              }
            }
            .lossArrow {
              width: 14px;
              transform: rotate(225deg);
              path {
                stroke: #E62E2E;
                stroke-width: 0.6;
              }
            }
            .growthArrow {
              width: 14px;
              transform: rotate(45deg);
              path {
                stroke: #18B368;
                stroke-width: 0.6;
              }
            }
          }
          .desc {
            font-size: 20px;
            font-weight: 400;
            color: #101323;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .evenNumber {
              color: #0081CC;
            }
            .lossNumber {
              color: #E62E2E;
            }
            .growthNumber {
              color: #18B368;
            }
            .evenCont {
              font-weight: 300;
              font-size: 13px;
              color: #0081CC;
            }
            .lossCont {
              font-weight: 300;
              font-size: 13px;
              color: #E62E2E;
            }
            .growthCont {
              font-weight: 300;
              font-size: 13px;
              color: #18B368;
            }
          }
        }
      }
    }
    .right {
      .headBar {
        border-bottom: 1px solid #EAECF0;
        .select {
          right: 2rem;
          div {
            padding: 6px 40px 6px 10px;
          }
          div:hover {
            background-color: #F2F4F7;
            color: #101323;
          }
        }
      }
      .body::-webkit-scrollbar-thumb {
        background-color: var(--secondary-blue);
      }
      .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 342px;
        overflow-y: auto;
        .bodyBar {
          padding: 18px 20px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          .title {
            color: #101323;
            font-size: 14px;
            font-weight: 500;
            max-width: 210px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

        }
      }
    }
  }

  .table {
    margin-top: 30px;
    border: 1px solid #EAECF0;
    border-radius: 10px;

    .tableBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.2rem 1rem 1.2rem;

      .head {
        color: #101323;
        font-size: 16px;
        font-weight: 400;
      }

      .tableActions {
        display: flex;
        gap: 1rem;
      }
    }

    .emptyTable {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .tableBodyItem {
      font-size: 13px;
      font-weight: 300;
      color: #667085;
      padding: 0.5rem 1.2rem 0.5rem 1.2rem;
      // gap: 5px;
      grid-template-columns: 3fr 1.2fr 1.3fr 1fr 1.5fr 0.8fr 0.2fr;
    }

    .tableHeader {
      grid-template-columns: 3fr 1.2fr 1.3fr 1fr 1.5fr 0.8fr 0.2fr;
      padding: 1rem 1.2rem 1rem 1.2rem;
      width: 100%;
      // gap: 5px;
    }

    .tableHeaderItem {
      // width: 100%;
      // gap: 5px;
    }

    .tableItem {
      display: flex;
      align-items: center;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
  .employerSelected {
    padding: 8px 16px;
    background: #101323;
    border-radius: 8px;
    margin-top: 20px;
    position: fixed;
    left: calc(256px + 1.8rem);
    right: 1.8rem;
    margin: 0 auto;
    bottom: 10px;

    @include flex-center(space-between, center);

    .amount {
      color: #FFFFFF;
      font-weight: 300;
    }

    .buttons {
      @include flex-center(center, center);
      gap: 10px;

      .requestBtn {
        background: #FFFFFF;
        border: none;
        color: #1D2939;
        @include button(6px, 14px, 300, 4px 8px);
      }

      .deleteBtn {
        @include button(6px, 14px, 300, 4px 8px);
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .candidates {
    .employerSelected {
      left: 1.8rem;
    }
  }

}

@media screen and (max-width: 900px) {
  .candidates {
    .header {
      .left {
        .head {
          font-size: 20px;
        }

        .subHead {
          font-size: 12px;
        }
      }

      .right {
        gap: 10px;
      }
    }

    .bigCards {
      .left {
        justify-content: space-between;
        .headBar {
          .headd {
            font-size: 14px;
          }
        }
        .chart {
          margin: 10px 0px;
          width: 40%;
        }
        .foot {
          margin-top: 40px;
          gap: 10%;
          .leftFoot {
            .new {
              padding: 6px;
              .newArrow {
                width: 10px;
              }
            }
            .newDesc {
              font-size: 16px;
              .cont {
                font-size: 12px;
              }
            }
          }
        }
      }
      .right {
        .body {
          .bodyBar {
            padding: 12px 20px;
            .title {
              max-width: 160px;
              font-size: 13px;
              font-weight: 300;
            }
          }
        }
      }
    }

    .table {

      .paginationBtn {
        padding: 5px 8px;
        font-size: 12px;
      }

      .paginText {
        font-size: 12px;
        gap: 1rem !important;
      }

      .pagin {
        padding: 3px 0.3rem;
      }
    }
    .employerSelected {
      left: 1.2rem;
      right: 1.2rem;
    }
  }
}


@media screen and (max-width: 800px) {
  .candidates {
    .cards {
      .cardScroll {
        .card {
          width: calc((100% / 3) - (2 / 3 * 1rem));
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .candidates {
    .header {
      .left {
        .head {
          font-size: 18px;
        }

        .subHead {
          font-size: 12px;
        }
      }

      .right {
        gap: 10px;

        .leftArrow {
          padding: 3px 3px 0px 3px;

          svg {
            width: 12px;
            height: auto;
          }
        }

        .rightArrow {
          padding: 3px 3px 0px 3px;

          svg {
            width: 12px;
            height: auto;
          }
        }
      }
    }
    .cards {
      .cardScroll {
        .card {
          width: calc((100% / 3) - (2 / 3 * 1rem));
        }
      }
    }
    .bigCards {

      .left {
        .chart {
          width: 60%;
        }
        .foot {
          margin-top: 40px;
          gap: 20%;
          .leftFoot {
            .newDesc {
              font-size: 14px;
              .cont {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
    .table {
      .tableBar {
        padding: 0.6rem 1.2rem;

        .head {
          font-size: 14px;
        }

        .tableActions {
          .search {
            padding: 9.5px 15px;
            height: fit-content;

            svg {
              width: 12px;
              min-width: 12px;
            }

            input {
              font-size: 11px;
              width: 5rem;
              height: 10px;
            }

            input::placeholder {
              font-size: 11px;
            }
          }

          .select {
            width: 12px;
          }
        }
      }

      .pagin {
        padding: 2px 0.35rem;
      }

      .paginationBtn {
        padding: 5px 8px;
        font-size: 12px;
      }
    }
    .employerSelected {

      .amount {
        font-size: 12px;
      }

      .buttons {
        .requestBtn {
          @include button(6px, 12px, 300, 4px 8px);

          svg {
            width: 10px;
            height: auto;
            margin-top: -2px;
          }
        }

        .deleteBtn {
          @include button(6px, 12px, 300, 4px 8px);

          svg {
            width: 10px;
            height: auto;
            margin-top: -2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .candidates {
    .bigCards {
      grid-template-columns: 1fr;
      .left {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .headBar {
          grid-column: 1 / -1; /* This spans the whole width */
        }
        .chart {
          grid-column: span 1;
          margin-left: 10%;
          display: flex;
          justify-content: center;
          width: auto;
        }
        .foot {
          grid-column: span 1;
          flex-direction: column;
          gap: 30px;
          align-items: flex-end;
          justify-content: center;
          margin-top: 0px;
        }
      }
      .right {
        grid-template-columns: 1fr;
        .body {
          .bodyBar {
            .title {
              max-width: 250px;
            }
          }
        }
      }
    }
    .cards {
      .cardScroll {
        margin-top: 20px;

        .card {
          width: calc((100% / 2) - (1 / 2 * 1rem));
        }
      }
    }
    .employerSelected {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      .buttons {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .candidates {
    .header {
      flex-direction: column;

      .right {
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;

        .leftArrow {
          padding: 3px 10px 0px 10px;

          svg {
            width: 15px;
            height: auto;
          }
        }

        .rightArrow {
          padding: 3px 10px 0px 10px;

          svg {
            width: 15px;
            height: auto;
          }
        }
      }
    }
    .bigCards {
      .left {
        .foot {
          .leftFoot {
            .newDesc {
              font-size: 12px;
              gap: 2px;
            }
          }
        }
      }
    }
    .cards {
      .cardScroll {
        margin-top: 20px;

        .card {
          width: calc((100% / 1) - (1 * 1rem));
        }
      }
    }
    .table {
      .tableBar {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
    }
  }
}