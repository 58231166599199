.toastContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 99999;
  
  .toast {
    margin-top: 20px;
    padding: 10px 15px;
    border-left: 5px solid var(--secondary-blue);
    box-shadow: 15px 15px 15px -2px rgba(16, 24, 40, 0.08);
    background: #fff;
    display: flex;
    justify-content: space-between;
    min-width: 250px;
    gap: 20px;
    .body {
      gap: 10px;
      .head {
        font-weight: 700;
      }
    }
    .closeIcon {
      cursor: pointer;
      width: 10px;
      height: 10px;
    }
  }
}