.afterFilter {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .openFilter {
    cursor: pointer;
  }

  .filterRange {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #EAECF0;

    p {
      white-space: nowrap;
    }

    .close {
      cursor: pointer;
      path {
        fill: #667085;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .afterFilter {
    gap: 6px !important; 
    padding: 8px 15px;
    .openFilter {
      width: 15px;
      margin-top: 5px 
    }
    .filterRange {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 6px 15px;
      .rangeIcon {
        padding-top: 3px;
        svg {
          width: 12px;
        }
      }
  
      p {
        font-size: 12px;
      }
  
      .close {
        width: 9px;
      }
    }
  }
}