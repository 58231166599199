.namee {
  align-items: center;
  overflow-x: auto;
  scrollbar-width: none;
  margin-right: 10px;

  .scrollable {
    white-space: nowrap;
  }
}

.email {
  text-transform: lowercase;
}

.doughnut {
  width: 50px;
  height: 50px;
  canvas {
    width: 50px;
    height: 50px;
  }
}

.statusSection {
  .status {
    display: flex;
    gap: 4px;
    padding: 2px 5px;
    border-radius: 5px;

    .dotClosed {
      rect {
        fill: #E62E2E;
      }
    }

    .dotScheduled {
      rect {
        fill: #DB8400;
      }
    }
  }

  .textClosed {
    background: #FFF2F0;
    color: #E62E2E;
  }

  .textScheduled {
    background: #FFF5E6;
    color: #EEA23E;
  }

  .textActive {
    background: rgba(230, 255, 243, 0.7);
    color: rgba(57, 205, 98, 1);
  }
}

.selectIcon {
  display: flex;
  justify-content: center;
}

.tableBody {
  padding: 0;
}

@media screen and (max-width: 1100px) {
  .nameSection {
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;
  }

  .namee {
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;

    .scrollable {
      white-space: nowrap;
    }
  }

  .nameSection::-webkit-scrollbar {
    width: 0;
    /* for Chrome, Safari, and Opera */
  }

  .nameSection::-webkit-scrollbar {
    height: none;
  }

}

@media screen and (max-width: 650px) {
  .nameSection {
    gap: 0.5rem;

    .check {
      width: 16px;
      height: 16px;
      border-radius: 3px;
    }
  }
}