.modalDialog {
  background: rgba(16, 19, 35, 0.6);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  overflow: auto;
  padding: 1rem;
  z-index: 99999;
}
.modalContent {
  background: #fff;
  max-width: fit-content;
  z-index: 99999999;
  margin: auto;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  overflow: hidden;
  height: fit-content;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.left {
  margin-left: 1rem;
}

.right {
  margin-right: 1rem;
}

@media screen and (max-width: 1100px){
  .modalContent {
    margin-top: 60px;
  }
}