.main {
  position: relative;
  .progress {
    border: 1px solid #EAECF0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 1rem;

    .item {
      display: flex;
      gap: 0.5rem;
      .dot {
        rect {
          fill: #D0D5DD;
        }
      }
    }

    .chevron {
      width: 15px;
      cursor: pointer;

      path {
        stroke-width: 3;
        stroke: #667085;
      }
    }
  }

  .sortList {
    box-shadow: 0px 25px 25px -8px rgba(16, 24, 40, 0.1);
    border-radius: 6px;
    position: absolute;
    background: #FFFFFF;
    z-index: 9999;
    transition: transform 0.3s ease;
    right: 0rem;

    .item:hover {
      color: #101323;
    }

    .item {
      font-size: 13px;
      font-weight: 300;
      padding: 12px 40px 12px 15px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 7px;
      cursor: pointer;
      .icon {
        rect {
          fill: #667085;
        }
      }
      .abandoned {
        rect {
          fill: #E62E2E;
        }
      }
      .completed {
        rect {
          fill: #3DCC87;
        }
      }
      .started {
        rect {
          fill: #EDA12F;
        }
      }

    }
  }
}

@media screen and (max-width: 600px) {
  .main {
    .progress {
      .item {
        font-size: 12px;
      }
    }
    .sortList {
      .item {
        font-size: 12px;
      }
    }
  }
}