.icons {
  gap: 1rem;
  position: relative;
  padding-bottom: 10px;
    .eye {
      path {
        fill: #667085;
      }
  }

  .tooltip {
    position: absolute;
    z-index: 999;
    padding: 8px 12px;
    background-color: #fff;
    border: 1px solid #dfe1e5;
    border-radius: 6px;
    text-align: center;
    color: #101323;
    top: 25px;
    right: -28px;

    .tooltipArrow {
      position: absolute;
      top: -6px;
      left: 43%;
      width: 0;
      height: 0;
      border-top: 1px solid #dfe1e5;
      border-right: 1px solid #dfe1e5;
      background-color: #fff;
      height: 10px;
      width: 10px;
      transform: rotate(-45deg);
    }

    .tooltipContent {
      position: relative;
      white-space: nowrap;
    }
  }

}