@mixin flex-center($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-style($color, $font-weight, $cursor: auto) {
  color: $color;
  font-weight: $font-weight;
  cursor: $cursor;
}

.main {
  .filterTabs {
    margin-top: 20px;
  }

  .assessments {
    .cards {
      .cardScroll {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.2rem;
      }
    }

    .candidateInfo {
      margin: 30px 0px;

      .candidateHead {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        .candidateTitle {
          font-weight: 500;
          font-size: 16px;
          color: #101323;
        }

        .right {
          display: flex;
          align-items: center;
          gap: 15px;

          .leftArrow {
            background: #F9FAFB;
            padding: 8px 8px 4px 8px;
            border-radius: 3px;
            cursor: pointer;
          }

          .rightArrow {
            background: #F9FAFB;
            padding: 8px 8px 4px 8px;
            transform: rotate(180deg);
            border-radius: 3px;
            cursor: pointer;
          }
        }
      }

      .candidateCards {
        display: flex;
        overflow-x: scroll;
        scroll-behavior: smooth;
        gap: 1rem;

        .card {
          flex: 0 0 auto;
          width: calc((100% / 3) - (2 / 3 * 1rem));
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #EAECF0;
          border-radius: 8px;
          padding: 16px 20px;

          .left {
            display: flex;
            align-items: center;
            gap: 1rem;

            .icon {
              padding: 8px 8px 4px 8px;
              border-radius: 5px;
              background: #605BFF;
              width: fit-content;
              height: fit-content;
            }

            .texts {
              display: flex;
              flex-direction: column;

              .textTitle {
                font-size: 12px;
                color: #667085;
              }

              .textNo {
                color: #101323;
                font-size: 36px;
                line-height: 35px;
                font-weight: 800;
                font-family: 'Darker Grotesque';
              }
            }
          }

          .rate {
            display: flex;
            align-items: center;
            gap: 8px;

            .growth {
              background: rgba(230, 255, 243, 0.7);
              display: flex;
              gap: 4px;
              border-radius: 5px;
              padding: 2px 8px;

              .growthDesc {
                font-size: 13px;
                color: #18B368;
              }

              .growthArrow {
                path {
                  stroke: #18B368;
                }
              }
            }

            .new {
              background: #F5FAFF;
              display: flex;
              gap: 4px;
              border-radius: 5px;
              padding: 2px 8px;

              .newDesc {
                font-size: 13px;
                color: #0081CC;
              }

              .newArrow {
                path {
                  stroke: #0081CC;
                }
              }
            }

            .loss {
              background: #FFE6E6;
              display: flex;
              gap: 4px;
              border-radius: 5px;
              padding: 2px 8px;

              .lossDesc {
                font-size: 13px;
                color: #E62E2E;
              }

              .lossArrow {
                transform: rotate(180deg);

                path {
                  stroke: #E62E2E;
                }
              }
            }

          }

        }
      }
    }

    .chart {
      margin-top: 30px;
      border: 1px solid #EAECF0;
      border-radius: 10px;
      width: 100%;

      .chartHead {
        padding: 16px 20px;
        border-bottom: 1px solid #EAEBF0;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
        color: #101323;
      }

      .chartGraph {
        padding: 0px 1rem 30px 2rem;
        height: 340px;
        width: 100%;
      }

      .chartLine {
        border-top: 1px solid #D0D5DD;
        display: flex;
        gap: 20px;
        width: 95%;
        margin: 0 auto;

        .active {
          color: #605BFF;
        }

        span {
          font-size: 16px;
          font-weight: 400;
          padding: 15px 0px;
          color: #101323;
          cursor: pointer;
          margin-top: -1px;
        }

        span:hover {
          border-top: 1px solid #605BFF;
        }
      }
    }

    .filterScroll {

      .filterTab {
        margin-top: 20px;
        border-radius: 8px;
        padding: 4px 8px;
        background: #F9FAFB;
        display: flex;
        width: fit-content;
        gap: 0.5rem;

        .filterBtn {
          padding: 10px 35px;
          color: #101323;
          font-weight: 300;
          font-size: 16px;
          cursor: pointer;
        }

        .filterBtn:hover {
          box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
          background: #FFFFFF;
          border-radius: 8px;
          font-weight: 400;
        }

        .active {
          box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
          background: #FFFFFF;
          border-radius: 8px;
          font-weight: 400;
        }

      }
    }



    .table {
      margin-top: 30px;
      border: 1px solid #EAECF0;
      border-radius: 10px;

      .tableBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1.2rem 1rem 1.2rem;

        .head {
          color: #101323;
          font-size: 16px;
          font-weight: 400;
        }

        .tableActions {
          display: flex;
          gap: 1rem;

          .filterScroll {
            max-width: 500px;

            .filter {
              display: flex;
              align-items: center;
              overflow-x: scroll;
              scroll-behavior: smooth;
              gap: 1rem;

              .filterRange {
                p {
                  white-space: nowrap;
                }
              }
            }
          }

          .selectContainer {
            display: flex;
            align-items: center;
            position: relative;

            .prev {
              cursor: pointer;
              transition: padding 0.6s ease, border-radius 0.6s ease;

              svg {
                width: 13px;
              }
            }

            .selectIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 9px 3px;
              border-radius: 5px;
              background-color: #101323;
              transition: padding 0.6s ease, border-radius 0.6s ease;
              cursor: pointer;

              svg {
                width: 13px;

                path {
                  stroke: #FFFFFF;
                }
              }
            }

            .tableEdit {
              box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
              border-radius: 6px;
              width: 380px;
              position: absolute;
              background: #FFFFFF;
              z-index: 9999;
              padding: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              transition: transform 0.3s ease;
              right: -15px;
              top: 50px;

              .headerr {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;

                .titles {
                  .top {
                    color: #101323;
                    font-family: 'Darker Grotesque';
                    font-size: 18px;
                    line-height: 1;
                    height: fit-content;
                    font-weight: 700;
                    margin-top: -6px;
                  }

                  .sub {
                    margin-top: 5px;
                  }
                }

                .close {
                  border: 1px solid #101323;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 5px;
                  cursor: pointer;
                  width: fit-content;
                  height: fit-content;

                  svg {
                    width: 6px;
                    height: 6px;

                    path {
                      fill: #101323;
                    }
                  }
                }
              }

              @mixin margin-bottom($margin) {
                margin-bottom: $margin;
              }

              .bodyHead {
                width: 92%;
                @include flex-center(space-between, center);
                @include margin-bottom(15px);

                .show {
                  @include text-style(#101323, 300);
                }

                .hide {
                  @include text-style(#605BFF, 400, pointer);
                }
              }

              .bodyContent {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 25px;
                padding: 0px 3% 20px 3%;
                border-bottom: 2px solid #F2F4F7;
                position: relative;

                .row {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .name {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .dragHandle {
                      cursor: grab;
                    }

                    .selects {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: fit-content;
                      padding: 0px;



                      svg {

                        path {
                          stroke: #D0D5DD;
                        }
                      }
                    }

                    .headerName {
                      color: #101323;
                      font-size: 14px;
                      font-weight: 300;
                    }
                  }

                  .eye {
                    cursor: pointer;
                  }
                }

                .none {
                  border: 1px solid;
                  display: none;
                }
              }

              .hidden {
                border-bottom: none;

                .row {
                  .name {
                    .headerName {
                      color: #667085;
                    }
                  }
                }
              }

              .hiddenHead {
                margin-top: 25px;
              }
            }
          }
        }
      }

      .emptyTable {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .tableWrap {
        min-width: 700px;
      }

      .tableHeader {
        grid-template-columns: 1.5fr 1.3fr 1.3fr 1fr 0.5fr;
      }

      .tableBodyItem {
        font-size: 13px;
        font-weight: 300;
        color: #667085;
        padding: 20px 1.2rem 20px 1.2rem;
        grid-template-columns: 1.5fr 1.3fr 1.3fr 1fr 0.5fr;
      }

      .draftHeader {
        grid-template-columns: 2fr 1.3fr 2fr 0.5fr;
        padding: 0.8rem 1.2rem 0.8rem 1.2rem;
      }

      .draftBodyItem {
        grid-template-columns: 2fr 1.3fr 2fr 0.5fr;
        padding: 20px 1.2rem 20px 1.2rem;
      }

      .tableItem {
        display: flex;
        align-items: center;
      }

      .headerStyle:last-of-type {
        visibility: visible;
      }

      .paginationBtn {
        background: none;
        color: #667085;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .main {
    .assessments {
      .table {
        .draftHeader {
          gap: 0px;
        }

        .draftBodyItem {
          gap: 0px;
        }
      }

      .candidateInfo {
        .candidateCards {
          .card {
            flex: 0 0 calc((100% / 2) - (1 / 2 * 1rem));
          }
        }
      }
    }
  }

}

@media screen and (max-width: 800px) {
  .main {
    .assessments {
      .filterScroll {
        overflow-x: scroll;
          scroll-behavior: smooth;
        .filterTab {
          max-width: 750px;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .main {
    .assessments {
      .filterTab {
        padding: 4px 8px;

        .filterBtn {
          padding: 6px 25px;
          font-size: 14px;
        }
      }

      .table {
        .tableBar {
          padding: 0.6rem 1.2rem;

          .head {
            font-size: 14px;
          }

          .tableActions {
            align-items: center;

            .filterScroll {
              max-width: 200px;

              .filter {
                display: flex;
                align-items: center;
                overflow-x: scroll;
                scroll-behavior: smooth;
                gap: 1rem;

                .filterRange {
                  p {
                    white-space: nowrap;
                  }
                }
              }
            }

            .search {
              padding: 10px 10px;
              height: fit-content;

              svg {
                width: 12px;
                min-width: 12px;
              }

              input {
                font-size: 12px;
                width: 5rem;
                height: 10px;
              }

              input::placeholder {
                font-size: 11px;
              }
            }

            .select {
              width: 12px;
            }
          }
        }

        // .tableHeader {
        //   grid-template-columns: 1.5fr 1.3fr 1.3fr 0.5fr 0.5fr;
        //   gap: 10px;
        // }

        // .tableBodyItem {
        //   grid-template-columns: 1.5fr 1.3fr 1.3fr 0.5fr 0.5fr;
        //   gap: 10px;
        // }

        // .draftHeader {
        //   grid-template-columns: 1fr 1fr 1.6fr 0.5fr;
        // }

        // .draftBodyItem {
        //   grid-template-columns: 1.5fr 1fr 1.6fr 0.5fr;
        // }
      }

      .cards {
        overflow-x: auto;
        scrollbar-width: none;

        .cardScroll {
          min-width: 600px;
        }
      }

    }
  }

}

@media screen and (max-width: 600px) {
  .main {
    .assessments {
      .candidateInfo {
        .candidateCards {
          gap: 0;

          .card {
            flex: 0 0 100%;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 550px) {
  .main {
    .assessments {
      .table {
        .tableBar {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          .tableActions {
            .filterScroll {
              max-width: 240px;
            }

            .search {
              padding: 11px 10px;
              height: fit-content;

              svg {
                width: 12px;
                min-width: 12px;
              }

              input {
                font-size: 12px;
                width: 5rem;
                height: 10px;
              }

              input::placeholder {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 350px) {
  .main {
    .assessments {
      .table {
        .tableBar {
          flex-direction: column;
          gap: 5px;

          .head {
            width: 100%;
            text-align: left;
          }

          .tableActions {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }
  }

}