@mixin flex-center($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-style($color, $font-weight, $cursor: auto) {
  color: $color;
  font-weight: $font-weight;
  cursor: $cursor;
}

.main {
  .reviews {
    .head {
      font-size: 22px;
      margin-top: 30px;
      color: #101323;
      font-weight: 500;
    }

    .filterTab {
      border-radius: 8px;
      padding: 4px 8px;
      background: #F9FAFB;
      display: flex;
      width: fit-content;
      gap: 0.5rem;

      .filterBtn {
        padding: 7px 25px;
        color: #101323;
        font-weight: 300;
        font-size: 14px;
        cursor: pointer;
      }

      .filterBtn:hover {
        // box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
        background: #FFFFFF;
        border-radius: 8px;
        font-weight: 400;
      }

      .active {
        // box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
        background: #FFFFFF;
        border-radius: 8px;
        font-weight: 400;
      }

    }

    .table {

      .tableBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.5rem 0rem 1.5rem 0rem;

        .head {
          color: #101323;
          font-size: 16px;
          font-weight: 400;
        }

        .tableActions {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          gap: 1rem;

          .selectContainer {
            display: flex;
            align-items: center;
            position: relative;

            .prev {
              cursor: pointer;
              transition: padding 0.6s ease, border-radius 0.6s ease;

              svg {
                width: 13px;
              }
            }

            .selectIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 9px 3px;
              border-radius: 5px;
              background-color: #101323;
              transition: padding 0.6s ease, border-radius 0.6s ease;
              cursor: pointer;

              svg {
                width: 13px;

                path {
                  stroke: #FFFFFF;
                }
              }
            }
          }
        }
      }

      .singleReport {
        border: 1px solid #EAECF0;
        border-radius: 10px;

        .emptyTable {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .tableWrap {
          min-width: 500px;
        }

        .tableHeader {
          grid-template-columns: 2fr 1fr 6fr;
        }

        .tableBodyItem {
          font-size: 13px;
          font-weight: 300;
          color: #667085;
          padding: 20px 1.2rem 20px 1.2rem;
          grid-template-columns: 2fr 1fr 6fr;
        }

        .tableItem {
          display: flex;
          align-items: center;
        }

        .reportTableHeader {
          grid-template-columns: 2fr 1fr 6fr;
          border-top: none;
          background-color: none;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }

        .reportTableBodyItem {
          font-size: 13px;
          font-weight: 300;
          color: #667085;
          padding: 6px 1.2rem 6px 1.2rem;
          grid-template-columns: 2fr 1fr 6fr;
        }

        .headerStyle:nth-last-of-type(1),
        .headerStyle:nth-last-of-type(2) {
          visibility: visible;
          display: block;
        }

        .paginationBtn {
          background: none;
          color: #667085;
        }
      }
    }
  }
}



@media screen and (max-width: 900px) {
  .main {
    .reviews {
      .table {
        .tableBar {
          flex-direction: column;
          gap: 20px;
          align-items: flex-start;
        }
      }
    }
  }

}

@media screen and (max-width: 650px) {
  .main {
    .reviews {
      .table {
        .tableBar {
          flex-direction: column;
          gap: 15px;
          align-items: flex-start;
          padding: 1rem 0rem 1rem 0rem;

          .tableActions {
            align-items: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 590px) {
  .main {
    .reviews {
      .table {
        .tableBar {
          overflow-x: scroll;
          scroll-behavior: smooth;

          .filter {
            overflow-x: scroll;
            scroll-behavior: smooth;

            .filterTab {
              max-width: 530px;

              .filterBtn {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

}