.nameSection {
  align-items: center;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  gap: 1rem;

  .check {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .nameFlex {
    display: flex;
    flex-direction: column;

    .businessName {
      color: #101323;
      font-weight: 500;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .businessEmail {
      text-transform: lowercase;
      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}

.namee {
  align-items: center;
  overflow-x: auto;
  scrollbar-width: none;
  margin-right: 10px;

  .scrollable {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}



.company {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress {
  text-transform: capitalize;
}

.statusSection {
  .status {
    display: flex;
    gap: 4px;
    padding: 2px 5px;
    border-radius: 5px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .dotabandoned {
      rect {
        fill: #E2341D;
      }

    }

    .dotcompleted {
      rect {
        fill: #18B368;
      }

    }

    .dotstarted {
      rect {
        fill: #EDA12F;
      }
    }
  }

  .textabandoned {
    background: #FFF2F0;
    color: #E2341D;
  }

  .textcompleted {
    background: rgba(230, 255, 243, 0.7);
    color: rgba(57, 205, 98, 1);
  }

  .textstarted {
    background: #FFF5E6;
    color: #EDA12F;
  }


}

.dots {
  position: absolute;
  right: 1rem;
}



.selectIcon {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .nameSection {
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;
  }

  .namee {
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;
  }

}

@media screen and (max-width: 650px) {
  .nameSection {
    gap: 0.5rem;

    .check {
      width: 16px;
      height: 16px;
      border-radius: 3px;
    }
  }
}